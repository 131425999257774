import React, { useState, useEffect} from "react";

import {
    Switch,
    Route,
    NavLink,
    useLocation,
    useHistory
  } from "react-router-dom";
import axios from 'axios';
import DashboardView from './DashboardView';
import SystemStatusView from './SystemStatusView';
import HelpAndSupportView from "./HelpAndSupportView";
import RanList from "./RANList";
import ConnectedDevices from "./ConnectedDevices";
import NetworkHealth from './NetworkHealth';
import AccountView from './AccountView';
import DoesNotExist from './DoesNotExist';
//import RulesManagement from './RulesManagement';
import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LoggedInTopBar from './LoggedInTopBar';
import Drawer from './Drawer';
import Modal from './Modal';
import Loading from './Loading';
import logo from '../images/cts-logged-in-logo.svg';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Dashboard } from '@material-ui/icons';
import { HealthAndSafety, 
        SystemSecurityUpdateGood,
        SettingsInputAntenna,
        DevicesOther,
       // Policy,
        Support,
        AccountCircle,
        Logout } from '@mui/icons-material';

function LoggedInView(props){

    const GroupsURL = "/api/ac/groups";
    const RolesURL = "/api/ua/roles";
    const NotificationsURL = "/api/ac/notifications";
    const ClearNotificationsURL = "/api/ac/notifications/clear";
    const UsersURL = "/api/ua/users";
    const AllSitesURL = "/api/ac/sites";
    const MySitesURL = "/api/ac/mysites";
    const SubscribersURL = "/api/sa/subscribers";

    const [activeLocation, setactiveLocation] = useState(0);
    const [activeLocationName, setactiveLocationName] = useState('All Sites');
    const [groups, setgroups] = useState(null);
    const [roles, setroles] = useState(null);
    const [userSites, setuserSites] = useState(null);
    const [users, setusers] = useState(null);
    const [notificationItems, setnotificationItems] = useState([]);
    const [drawerOpen, setdrawerOpen] = useState(false);
    const [drawerType, setdrawerType] = useState(null);
    const [childViewData, setchildViewData] = useState(null);
    const [utmData, setutmData] = useState(null);
    const [showModal, setshowModal] = useState(false);
    const [modalType, setmodalType] = useState(null);
    const [mobileNavVisible, setmobileNavVisible] = useState(false);
  
    const [scrolling, setScrolling] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const [systemStatus, setsystemStatus] = useState(100);
    const [lottiePlayStatus, setlottiePlayStatus] = useState(true);
    const [subscribers, setSubscribers] = useState(null);
    const [reloadingData, setreloadingData] = useState(true);

    let location = useLocation();
    let history = useHistory();

    useEffect(() => {
        window.scrollTo(0, 0);
        if(location.pathname.includes('password')){
            props.userLogout();
        }
    }, []); 

    const getSelectedSites = () => {
        setreloadingData(true);
        axios.get(MySitesURL).then((response) => {
  
            if(response.data.length > 1){
                setactiveLocation(0);
                setactiveLocationName('All Sites');
            } else{
                setactiveLocation(response.data[0].id);
                setactiveLocationName(response.data[0].name);
            }
           setTimeout(function(){
                setreloadingData(false);
           },800)
        }).catch(function(error){
            props.userLogout();
        });
    }

    const dashSiteUpdate = (arg1) => {
    
        setactiveLocation(false);

        let postArray = [arg1];

        if(arg1 === 0){
            postArray = [];
            for (var i=0; i < userSites.length; ++i)
            postArray.push(userSites[i].id);
       }

         axios.post(MySitesURL, postArray
           ).then((response) => {

             if(response.data.status === 1){
                 getSelectedSites();
             }
         }).catch(function(error){
             props.userLogout();
         });
    }
    
    const handleLocationSelect = event => {
        setactiveLocation(false);
       let postArray = [event.target.value];
       if(event.target.value === 0){
            postArray = [];
            for (var i=0; i < userSites.length; ++i)
            postArray.push(userSites[i].id);
       }
        axios.post(MySitesURL, postArray
          ).then((response) => {
   
            if(response.data.status === 1){
                getSelectedSites();

            }
        }).catch(function(error){
            props.userLogout();
        });
    };

    const getUsers = () => {
        axios.get(UsersURL).then((response) => {
            setusers(response.data);
            setTimeout(function(){
               // setreloadingData(false);
            }, 500);
        }).catch(function(error){
            props.userLogout();
        });
    }

    const getSubscribers = () => {
        axios.get(SubscribersURL).then((response) => {
            setSubscribers(response.data);
            setTimeout(function(){
                setreloadingData(false);
            }, 700);
        }).catch(function(error){
            props.userLogout();
        });
    }

    const forceUpdateUsers = () =>{
        setreloadingData(true);
        
        setSubscribers(false);
        setTimeout(function(){
            getUsers();
            getSubscribers();
        },500);
    }


    const getNotifications = () => {
        if(props.loginStatus === true){
            axios.get(NotificationsURL
                ).then((response) => {
                let notificationsItems = response.data.notification_list;
                setnotificationItems(notificationsItems);
                setsystemStatus(response.data.system_status);
                setTimeout(function(){
                    getNotifications();
                },30000);
            }).catch(function(error){
                props.userLogout();
            });
        }
    }

    



    const updateLottiePlay = () => {
        setlottiePlayStatus(false);
        setTimeout(() => {
            setlottiePlayStatus(true);
        }, 10);
    }
    const handleBellClick=()=>{
        setDrawerClass('notifications-drawer');
        drawerToggle();
    }

    useEffect(() => {
        
        axios.get(GroupsURL).then((response) => {
            setgroups(response.data);
       
        }).catch(function(error){
            props.userLogout();
        });
        axios.get(RolesURL).then((response) => {
            setroles(response.data);
        }).catch(function(error){
            props.userLogout();
        });
        
        axios.get(AllSitesURL
          ).then((response) => {
            setuserSites(response.data);
        }).catch(function(error){
            props.userLogout();
        });

        getSelectedSites();
        getSubscribers();
        getUsers();
        
    }, []);



    useEffect(() => {
        const onScroll = e => {
          setScrollTop(e.target.documentElement.scrollTop);
          setScrolling(e.target.documentElement.scrollTop > scrollTop);
        };
        window.addEventListener("scroll", onScroll);
    
        return () => window.removeEventListener("scroll", onScroll);
      }, [scrollTop]);

    // Whenever route changes
    useEffect(() => {
        // Get updated notifications
        if(props.loginStatus === true){
            setTimeout(function(){
                getNotifications();
            },200);
            
        }
    }, [location]);

     const drawerToggle = () =>{
            if(drawerOpen === false){
                setTimeout(() => {
                    if(drawerType !== 'subscriber-detail-drawer'){
                        updateLottiePlay();
                    } 
                    document.body.classList.add("no-scroll")
                },300)
            } else{
                document.body.classList.remove("no-scroll")
            }
        setdrawerOpen(!drawerOpen); 
     }
     
    const setDrawerClass = (drawerClass) => {
        setdrawerType(drawerClass);
    }

    const triggerModal = () => {
        setshowModal(!showModal);
    }

    const setModalClass = (modalClass) => {
        setmodalType(modalClass);
    }

    const mobileNavToggle = (param) =>{
        setmobileNavVisible(!mobileNavVisible);
    }

    
    const emptyArray = [];

    const clearNotifications = (listToClear) => {
        axios.post(ClearNotificationsURL, listToClear).then((response) => {
          getNotifications();
        }).catch(function(error){
            props.userLogout();
        });
    }

    const handleClearAll = () =>{
        let toclear = [];
        notificationItems.forEach(function (item) {
            toclear.push(item.id);
        });
        clearNotifications(toclear);
    }

    const handleClearNotification = (event) => {
        let clearID = parseInt(event.target.getAttribute('data-remove'));
        let toclear = [clearID];
        
        clearNotifications(toclear);
    }

    const childToParent = (ctpData) => {
        setchildViewData(ctpData);
    }

    const upstreamToModal = (apiMessage) => {
        setutmData(apiMessage);
    }

    const simsOrdered = () => {
        setModalClass('clear-modal');
        setTimeout(function(){
            setModalClass('sim-order-received');
            triggerModal();
        },20)
        
    }

    
   
    if(!groups || !roles || !userSites || !notificationItems) return null;
    
    return(
        <div className={`logged-in-view ${drawerOpen ? 'drawer-open' : ''}`}>
                <div className={`main-nav ${mobileNavVisible ? 'mobile-nav-open' : ''}`}>
                    <div className="navicon" onClick={mobileNavToggle}>
                        <span className="line line-1"></span>
                        <span className="line line-2"></span>
                        <span className="line line-3"></span>
                    </div>

                    <div className="logo-wrap">
                        <img src={logo} alt="Logo" />
                    </div>

                    <div className="notification-status-wrap tablet-only">
                        <LottieAnimation 
                            playStatus={lottiePlayStatus} 
                            lotti={statusDot} 
                            height={`auto`} 
                            width={`100%`} 
                            classProp={`status-circle green`} 
                        />
                        <div className="notifications-toggle" onClick={handleBellClick}>
                            <NotificationsNoneIcon className="bell-icon" />
                            {notificationItems.length >= 1 &&
                                <div className="count">{notificationItems.length}</div>
                            }
                        </div>
                    </div>

                    <FormControl>
                    <Select
                        id="location-select"
                        className={`sidebar-select ${scrollTop > 100 ? 'mobile-hide-select' : ''}`}
                        onChange={(event) => 
                            {
                            handleLocationSelect(event);
                            setmobileNavVisible(false);
                            }
                        }
                        defaultValue={activeLocation}
                        value={activeLocation}
                    >
                        <MenuItem value={0}>All Sites</MenuItem>
                        {userSites.map((site) => (
                            <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                    <div className="main-navigation-wrap">
                        <ul className="main-menu-items side-nav">
                            <li>
                            <NavLink exact to="/dashboard" onClick={mobileNavToggle}>
                                <Dashboard />Dashboard
                            </NavLink>
                            </li>
                            <li>
                            <NavLink to="/network-health" onClick={mobileNavToggle}>
                                <HealthAndSafety />Networks
                            </NavLink>
                            </li>
                            <li>
                            <NavLink to="/status" onClick={mobileNavToggle}>
                                <SystemSecurityUpdateGood />
                                System Status
                            </NavLink>
                            </li>
                            <li>
                            <NavLink to="/ran-view" onClick={mobileNavToggle}>
                                <SettingsInputAntenna />
                                RAN View
                            </NavLink>
                            </li>
                            <li>
                            <NavLink to="/subscribers" onClick={mobileNavToggle}>
                                <DevicesOther />
                                Subscribers
                            </NavLink>
                            </li>
                            <li>
                            <NavLink to="/contact" onClick={mobileNavToggle}>
                                <Support />
                                Help &amp; Support
                            </NavLink>
                            </li>
                        </ul>
                        <ul className="secondary-menu-items side-nav" onClick={mobileNavToggle}>
                            <li>
                                <NavLink to="/account" activeClassName="alt-class" onClick={mobileNavToggle}>
                                    <AccountCircle />
                                    My Account
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="" exact={true} onClick={() => {
                                        props.userLogout();
                                    }} activeClassName="alt-class">
                                    <Logout />
                                Logout
                                </NavLink>
                            </li>
                        </ul>
                    </div>


                {/*
                    A <Switch> looks through all its children <Route>
                    elements and renders the first one whose path
                    matches the current URL. Use a <Switch> any time
                    you have multiple routes, but you want only one
                    of them to render at a time
                */}
                </div>
                <div className="main-content-col">
                    {reloadingData &&
                    <Loading />}
                <LoggedInTopBar 
                    locationID={activeLocation}
                    location={activeLocationName} 
                    resetLocation={() => {
                        setactiveLocation(0);
                    }}
                    status={systemStatus} 
                    systemStatus={systemStatus === 100 ? 'All systems online' : 'Not all systems online'}
                    drawerToggle={drawerToggle}
                    setNotifications={setDrawerClass}
                    notificationsCount={notificationItems.length}
                    handleBellClick={handleBellClick}
                    lottiePlayStatus={lottiePlayStatus}
                    dashSiteUpdate={dashSiteUpdate}
                    userLogout={props.userLogout}
                />
                <Switch>
                    <Route exact path="/dashboard">
                    {(activeLocation || activeLocation === 0) &&
                        <DashboardView
                        loginStatus={props.loginStatus}
                            userLogout={props.userLogout}
                            activeLocation={activeLocation}
                            dashSiteUpdate={dashSiteUpdate}
                            setreloadingData={setreloadingData}
                        />
                    }
                    </Route>
                    <Route path="/network-health">
                        {(activeLocation || activeLocation === 0) &&
                            <NetworkHealth 
                                drawerToggle={drawerToggle}
                                setDrawerClass={setDrawerClass}
                                childToParent={childToParent}
                                lottiePlayStatus={lottiePlayStatus}
                                updateLottiePlay={updateLottiePlay}
                                setreloadingData={setreloadingData}
                                userLogout={props.userLogout}
                        />
                        }
                        
                    </Route>
                    <Route path="/contact">
                        <HelpAndSupportView 
                            simsOrdered={simsOrdered}
                            toggleModal={triggerModal}
                            setModalClass={setModalClass}
                            upstreamToModal={upstreamToModal}
                            setreloadingData={setreloadingData}
                            userLogout={props.userLogout}
                        />
                    </Route>
                    <Route path="/status">
                        <SystemStatusView 
                            lottiePlayStatus={lottiePlayStatus}
                            updateLottiePlay={updateLottiePlay}
                            setreloadingData={setreloadingData}
                            userLogout={props.userLogout}
                        />
                    </Route>
                    <Route path="/ran-view">
                         {(activeLocation || activeLocation === 0) &&
                        <RanList 
                            drawerToggle={drawerToggle}
                            setDrawerClass={setDrawerClass}
                            childToParent={childToParent}
                            lottiePlayStatus={lottiePlayStatus}
                            updateLottiePlay={updateLottiePlay}
                            setreloadingData={setreloadingData}
                            userLogout={props.userLogout}
                            toggleModal={triggerModal}
                            setModalClass={setModalClass}
                            upstreamToModal={upstreamToModal}
                        />
                         }
                    </Route>
                    <Route path="/subscribers">
                        {subscribers &&
                            <ConnectedDevices 
                            forceUpdateUsers={forceUpdateUsers}
                            subscribers={subscribers}
                            drawerToggle={drawerToggle}
                            setDrawerClass={setDrawerClass}
                            childToParent={childToParent}
                            groups={groups}
                            toggleModal={triggerModal}
                            setModalClass={setModalClass}
                            upstreamToModal={upstreamToModal}
                            lottiePlayStatus={lottiePlayStatus}
                            updateLottiePlay={updateLottiePlay}
                            setreloadingData={setreloadingData}
                            userLogout={props.userLogout}
                        />
                        }
                        
                    </Route>
                    {/* <Route path="/rules-managment">
                        <RulesManagement />
                    </Route> */}
                    <Route path="/account">
                        <AccountView 
                            forceUpdateUsers={forceUpdateUsers}
                            roles={roles}
                            toggleModal={triggerModal}
                            setModalClass={setModalClass}
                            upstreamToModal={upstreamToModal}
                            sites={userSites}
                            users={users}
                            simsOrdered={simsOrdered}
                            setreloadingData={setreloadingData}
                            userLogout={props.userLogout}
                        />
                    </Route>

                    <Route path="*">
                        <DoesNotExist 
                            
                        />
                    </Route>
                </Switch>
                </div>
                <Modal
                    showModal={showModal}
                    triggerModal={triggerModal}
                    modalType={modalType}
                    roles={roles}
                    sites={userSites}
                    forceUpdate={forceUpdateUsers}
                    setModalClass={setModalClass}
                    responseMessage={utmData}
                    upstreamToModal={upstreamToModal}
                    userLogout={props.userLogout}
                />
            
            <Drawer 
                drawerOpen={drawerOpen} 
                drawerToggle={drawerToggle}
                drawerType={drawerType}
                notificationItems={notificationItems}
                handleClearNotification={handleClearNotification}
                handleClearAll={handleClearAll}
                dataPassedUp={childViewData}
                groups={groups}
                lottiePlayStatus={lottiePlayStatus}
                forceUpdateUsers={forceUpdateUsers}
                toggleModal={triggerModal}
                setModalClass={setModalClass}
                upstreamToModal={upstreamToModal}
                userLogout={props.userLogout}
            />
            {reloadingData &&
            <Loading />}
            </div>
    );
}


export default LoggedInView;