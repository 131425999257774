import React, {useState} from 'react';
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function SupportTicketForm(props){
    const[subject, setsubject] = useState('');
    const[subjectError, setsubjectError] = useState(false);
    const[postBody, setpostBody] = useState('');
    const[postBodyError, setpostBodyError] = useState(false);
    const supportTicketURL = "/api/ac/ticket";

    const submitSupportTicket = (event) => {

        event.preventDefault();
        if(subject.length < 1){
            setsubjectError(true);
        }
        if(postBody.length < 1){
            setpostBodyError(true);
        }
        if(subject.length < 1 || postBody.length < 1){
            return;
        }
        axios.post(supportTicketURL, 'subject='+subject+'&body='+postBody).then((response) => {
            if(response.data.status === 1){
               props.toggleModal();
               props.setModalClass('ticket-submitted');
            } else{
                props.toggleModal();
                props.upstreamToModal(response.data.message);
                props.setModalClass('api-message-modal');
            }
            setsubject('');
            setpostBody('');
        }).catch(function(error){
            props.userLogout();
        });  
    }



    return(
        <form className="support-ticket-form" onSubmit={submitSupportTicket}>
            <TextField 
                    error={subjectError ? true : false}
                    label="Subject" 
                    variant="outlined" 
                    className="form-field" 
                    value={subject}
                    onChange={(event) => {
                        setsubject(event.target.value);
                        setsubjectError(false);
                    }}
                />
            <TextField 
                    error={postBodyError ? true : false}
                    label="Message" 
                    variant="outlined" 
                    rows={7}
                    className="form-field" 
                    multiline
                    value={postBody}
                    onChange={(event) => {
                        setpostBody(event.target.value);
                        setpostBodyError(false);
                    }}
                    helperText={<>
                    Please include the following details along with the description of the issue: 
                    <ul> 
                        <li>Site/Sites impacted</li>
                        <li>Radio Zones impacted if known</li>
                        <li>Devices/SIMs impacted</li>
                        <li>Date/time the issue was first noticed</li>
                    </ul>
                    </>
                    }
                />
                <Button 
                type="submit" 
                variant="contained" 
                className="yellow-button site-button large"
            >
            Create a ticket
            </Button>
        </form>
    )
}

export default SupportTicketForm;