import React from "react";
import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
import PieGraph from "./PieGraph";
import {ReactComponent as Arrow} from '../images/grey-arrow.svg';
function NetworkHealthCard(props){
   let inactiveUsers = props.siteData.number_of_connected_subscribers - props.siteData.number_of_active_subscribers;
   let usersStatus = (props.siteData.number_of_active_subscribers / props.siteData.number_of_connected_subscribers) * 100;

   let userGraphValues = [
                        { argument:'Inactive', value:inactiveUsers },
                        { argument:'Active', value:props.siteData.number_of_active_subscribers }
                    ]
    
    let inactiveRadios = props.siteData.number_of_available_radios - props.siteData.number_of_active_radios;
    let radiosStatus = (props.siteData.number_of_active_radios / props.siteData.number_of_available_radios) * 100;
    let radioGraphValues = [
                        { argument:'Inactive', value:inactiveRadios },
                        { argument:'Active', value:props.siteData.number_of_active_radios }
                    ]
    let siteData = props.siteData;
    let maxUplink =  siteData.uplink === 0? 'Unlimited':siteData.uplink + 'Mbps';
    let maxDownlink =  siteData.downlink === 0? 'Unlimited':siteData.downlink + 'Mbps';

    return(
        <div className="network-health-card">
            <div className="top-section nhc-segment">
               <div className="heading-and-status">
                    <div className="left-col">
                        <div className="left-col-top"> 
                            <h2 className="heading-4">{props.siteData.name}</h2>
                            <div className="status-dot-wrap">
                               
                                <LottieAnimation 
                                    lotti={statusDot} 
                                    height={`auto`} 
                                    width={`100%`} 
                                    classProp={`status-circle ${props.siteData.network_health < 50 ? 'red' : 'green'}`} 
                                    playStatus={props.lottiePlayStatus}
                                    />
                            </div>
                        </div>
                        <div className="left-col-bottom">
                            <div className="text-wrap">
                                <div className="tw-left">
                                    {props.siteData.number_of_active_subscribers}
                                </div>
                                <div className="tw-right">
                                    {/* <p>{`of ${props.siteData.number_of_connected_subscribers}`}</p> */}
                                    <p>active sessions</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="right-col">
                        {/* <div className="pie-graphs-wrap">
                            <div className="pie-graph-item">
                                <PieGraph 
                                    graphValues={userGraphValues}
                                    status={usersStatus >= 50 ? 'green' : 'red'}
                                />
                            </div>
                        </div> */}
                    </div>
               </div>
           </div>
           <div className="middle-section nhc-segment">
                <div className="section-item">
                    <h4>QCI</h4>
                    <p>{props.siteData.qci}</p>
                </div>
                <div className="section-item">
                    <h4>Max UpLink</h4>
                    <p>{maxUplink}</p>
                </div>
                <div className="section-item">
                    <h4>Max DownLink</h4>
                    <p>{maxDownlink}</p>
                </div>
                {/* <div className="section-item">
                    <h4>Max Bit Rate</h4>
                    <p>{`${props.siteData.bitrate}Mbps`}</p>
                </div> */}
           </div>
           <div className="bottom-section nhc-segment">
                <div className="view-details"  onClick={() => { props.handleDetailsClick(props.siteData.id) }}>View Network Details <Arrow /></div>
           </div>
        </div>
    )
}
export default NetworkHealthCard;