import React, {useState, useEffect} from "react";
import axios from "axios";
import { useHistory, useLocation } from 'react-router-dom';
import './App.scss';
import LoginView from "./components/LoginView";
import LoggedInView from './components/LoggedInView';
//import TestLoggedInView from './components/TestLoggedInView';

function App(props){
  const LogoutURL = "/api/auth/logout";
  const[loginStatus, setloginStatus] = useState(true);
  const history = useHistory();
  let location = useLocation();

  const handleLoginSubmit = () => {
    if(loginStatus === false){
      setloginStatus(true);
      setTimeout(function(){
        history.push('/dashboard');
      },500)
    } 
  }

  const userLogout = () =>{
    setloginStatus(false);
    axios.get(LogoutURL).then((response) => {
  
      if(location.pathname !== '/reset-password' && location.pathname !== '/forgot-password' && location.pathname !== '/create-password'){
        setTimeout(function(){
          history.push('/login');
        },500)}
      }
      ).catch(function(error){
        if(location.pathname !== '/reset-password' && location.pathname !== '/forgot-password' && location.pathname !== '/create-password'){
        history.push('/login');
        }
    });  
  }


  useEffect(() => {
    if(location.pathname === '/' ){
        setTimeout(function(){
            history.push('/dashboard');
        },600);
       
    }
}, []);

  return(
    <div className="cts-app">
        {loginStatus ? <LoggedInView userLogout={userLogout} loginStatus={loginStatus} /> : <LoginView handleLoginSubmit={handleLoginSubmit} userLogout={userLogout} />}
    </div>
  )
}

export default App;
