import React from 'react';
// import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import {ReactComponent as Progress} from '../images/yellow-loading.svg';
import {ReactComponent as GreenCheck} from '../images/green-check.svg';
import {ReactComponent as YellowError} from '../images/yellow-error.svg';
function SIMActivationProgress(props){
    return(
        <div className="sim-activation-content">
            {
                (props.activationStatus === 'sim-activation-progress' || props.activationStatus === 'request-process' ) &&
                <div>
                    <div className='status-icon-wrap'>
                        <Progress />
                    </div>
                    <h2>
                        {props.alternateHeading ? props.alternateHeading : 'Activating Your SIM'} 
                    </h2>
                    <p>{props.alternateParagraph ? props.alternateParagraph : 'Please give us a second...'}</p>
                </div>
            }
            {
                props.activationStatus === 'sim-activation-error' &&
                <div>
                    <div className='status-icon-wrap'>
                        <YellowError />
                    </div>
                    <h2>We encountered a problem.</h2>
                    
                    <Button 
                        // component={Link} 
                        // to="/" 
                        variant="contained" 
                        className="yellow-button site-button"
                        onClick={e => {
                            props.triggerModal()
                            props.forceUpdate()
                        }}
                    >
                        Return to Dashboard
                    </Button>
                    
                </div>
            }
            {
                (props.activationStatus === 'sim-activation-complete' || props.activationStatus === 'success-added') &&
                <div>
                    <div className='status-icon-wrap'>
                        <GreenCheck />
                    </div>
                    <h2>{props.alternateHeading ? props.alternateHeading : 'Your SIM is activated'}</h2>
                    
                    <Button 
                        // component={Link} 
                        // to="/" 
                        variant="contained" 
                        className="yellow-button site-button"
                        onClick={e => {
                            props.triggerModal()
                            props.forceUpdate() 
                        }}
                    >
                        Return to Dashboard
                    </Button>
                </div>
            }
                        {
                props.activationStatus === 'sim-order-received' &&
                <div>
                    <div className='status-icon-wrap'>
                        <GreenCheck />
                    </div>
                    <h2>Your Order Has Been Received</h2>
                    
                    <Button 
                        // component={Link} 
                        // to="/" 
                        variant="contained" 
                        className="yellow-button site-button"
                        onClick={e => {
                            props.triggerModal()
                            props.forceUpdate() 
                        }}
                    >
                        Return to Dashboard
                    </Button>
                </div>
            }
            
            
        </div>
    )
}

export default SIMActivationProgress;