import React, {useState, useEffect} from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';


export default function DataGrid(props) {
  const [pageSize, setPageSize] = useState(5);
  const [rows, setRows] = useState(props.rows);
  const [page, setpage] = useState(0);
  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  return (
 
      <DataGridPro
        className="responsive-table"
        rows={rows}
        columns={props.columns}
        onRowClick={props.handleRowClick ? (rowData) => {
         return props.handleRowClick(rowData.row)
        } : null }
        autoHeight={true} 
        checkboxSelection={false}
        page={page}
        pageSize={pageSize}
        rowCount={rows.length}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(data) => {
          setpage(data);
        }}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        disableColumnMenu={props.disableColumnMenu === true ? true : null}
        initialState={props.initialState}
      /> 
  );
}