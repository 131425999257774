import React, {useEffect, useState} from "react";
import axios from "axios";
import SystemStatusCard from './SystemStatusCard';

function SystemStatusView(props){

    const [systemStatus, setsystemStatus] = useState(null);
    const baseURL = "/api/ac/system-status";

    // Grab API Data and set state
    useEffect(() => {
        props.setreloadingData(true);
        axios.get(baseURL).then((response) => {
            setsystemStatus(response.data);
            props.updateLottiePlay();
            props.setreloadingData(false);
        }).catch(function(error){
            props.userLogout();
        });  
    }, []);

    if(!systemStatus) return null;
    
    return(
        <div className="system-status-view">
            <h2 className="view-heading">System Status</h2>
            <p>Status of HSS/MME, S/P GW and connectivity to core and RAN. View only.</p>
            <div className="status-blocks-row">
                <div className="row-cards">
                    <SystemStatusCard 
                        cardTitle="HSS/MME" 
                        dotColor={systemStatus.hss_mme.status === 100 ? "green" : "red"} 
                        statusNote={systemStatus.hss_mme.status_message} 
                        status={systemStatus.hss_mme.status === 100 ? "success" : "error"}
                        message={systemStatus.hss_mme.status_detail}  
                        lottiePlayStatus={props.lottiePlayStatus}
                    />
                    <SystemStatusCard 
                        cardTitle="S/P GW" 
                        dotColor={systemStatus.sgw_pgw.status === 100 ? "green" : "red"} 
                        statusNote={systemStatus.sgw_pgw.status_message} 
                        status={systemStatus.sgw_pgw.status === 100 ? "success" : "error"}
                        message={systemStatus.sgw_pgw.status_detail}  
                        lottiePlayStatus={props.lottiePlayStatus}
                    />
                </div>
            </div>
            <div className="status-blocks-row">
                <h2>Connectivity</h2>
                <div className="row-cards">
                    <SystemStatusCard 
                        cardTitle="Core Connectivity" 
                        dotColor={false} 
                        statusNote={systemStatus.core_connectivity.status_message} 
                        status={systemStatus.core_connectivity.status  === 100 ? "success" : "error"}
                        message={systemStatus.core_connectivity.status_detail}  
                    />
                    <SystemStatusCard 
                        cardTitle="RAN Connectivity" 
                        dotColor={false} 
                        statusNote={systemStatus.ran_connectivity.status_message} 
                        status={systemStatus.ran_connectivity.status  === 100 ? "success" : "error"}
                        message={systemStatus.ran_connectivity.status_detail}  
                    />
                </div>
            </div>
        </div>
    );
}

export default SystemStatusView;