
import React from "react";
import {
  Chart,
  PieSeries
} from '@devexpress/dx-react-chart-material-ui';

  const PieGraph = (props) => {
    return (
        <Chart
          data={props.graphValues}
          className={`pie-graph ${props.status}`}
        >
          <PieSeries 
            valueField="value" 
            argumentField="argument" 
            innerRadius={0.6}
            className="pie-graph"
          />
        </Chart>
    
    );
   
  };
  export default PieGraph;