import React, {useEffect, useState} from "react";
import {
    Switch,
    Route
  } from "react-router-dom";
import axios from 'axios';
import logo from '../images/cts-login-logo.svg';
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';
import CreatePasswordForm from './CreatePasswordForm';
import Modal from './Modal';
import Alert from '@mui/material/Alert';

function LoginView(props){
    const [showModal, setshowModal] = useState(false);
    const [modalType, setmodalType] = useState(null);
    const [termschecked, settermschecked] = useState(false);
    const [reRender, setRerender] = useState(true);
    const [showAlert, setshowAlert] = useState(false);
    const [showAlert2, setshowAlert2] = useState(false);
    const [forgotAlert, setforgotAlert] = useState(false);
    const [utmData, setutmData] = useState('');

    const forceUpdate = () =>{
        setRerender(!reRender);
    }

    const handletermsChange = (event) => {
        settermschecked(!termschecked);
    };

    const checkTerms = (event) => {
        settermschecked(true);
    };

    const showPWResetAlert = () =>{
        setshowAlert(!showAlert);
    }

    const showPWCreatedAlert = () =>{
        setshowAlert2(!showAlert2);
    }

    const showForgotAlert = () =>{
        setforgotAlert(!forgotAlert);
    }

    const triggerModal = () => {
        setshowModal(!showModal);
    }

    const setModalClass = (modalClass) => {
        setmodalType(modalClass);
    }

    const upstreamToModal = (apiMessage) => {
        setutmData(apiMessage);
    }
    const LoginURL = "/api/auth/login";
    
    const userLogin = (un, pw, rem) => {
        let submitData = '';
        if(rem){
            submitData = "email="+un+"&password="+pw+"&remember_me=on";
        } else{
            submitData = "email="+un+"&password="+pw;
        }
        axios({
            method: 'post',
            url: LoginURL,
            withCredentials: true,
            data: submitData
        })
        .then((response) => {
            if(response.data.status === 1 && response.data.session_id){
                setTimeout(function(){
                    props.handleLoginSubmit();
                },300);
            } else{
                props.userLogout();
                triggerModal();
                setmodalType('failed-login');
            }
        })
        .catch(function(error){
            props.userLogout();
            upstreamToModal(error.response.data.message);
            triggerModal();
            setmodalType('api-message-modal');
        })
    }


     // Check if cookies is enabled
     useEffect(() => {
       
        if(!navigator.cookieEnabled) {
            triggerModal();
            setModalClass('enable-cookies');
         } else{
            
         }
     }, []);

    return(
        <div className="login-view">
             {showAlert &&
                <Alert className="password-update-alert" onClose={() => {showPWResetAlert()}}>Your password has been reset.</Alert>
            }
            {showAlert2 &&
                <Alert className="password-update-alert" onClose={() => {showPWCreatedAlert()}}>Your password has been successfully created.</Alert>
            }
            {forgotAlert &&
                <Alert className="password-update-alert" onClose={() => {showForgotAlert()}}>Please check your inbox for a reset link.</Alert>
            }
            <div className="logo-wrap">
                <img src={logo} alt="Logo" />
            </div>

            <Switch>
                <Route exact path="/">
                    <LoginForm 
                        showModal={showModal}
                        triggerModal={triggerModal}
                        modalType={modalType}
                        setModalClass={setModalClass}
                        handletermsChange={handletermsChange}
                        termschecked={termschecked}
                        userLogin={userLogin} 
                    />
                </Route>
                <Route exact path="/login">
                    <LoginForm 
                        showModal={showModal}
                        triggerModal={triggerModal}
                        modalType={modalType}
                        setModalClass={setModalClass}
                        handletermsChange={handletermsChange}
                        termschecked={termschecked}
                        userLogin={userLogin}
                    />
                </Route>
                <Route path="/forgot-password">
                    <ForgotPasswordForm 
                        showForgotAlert={showForgotAlert}
                    />
                </Route>
                <Route path="/reset-password">
                <ResetPasswordForm
                    showPWResetAlert={showPWResetAlert}
                />
                </Route>
                <Route path="/create-password">
                <CreatePasswordForm
                    showPWCreatedAlert={showPWCreatedAlert}
                />
                </Route>
            </Switch>


             <Modal
                showModal={showModal}
                triggerModal={triggerModal}
                modalType={modalType}
                checkTerms={checkTerms}
                forceUpdate={forceUpdate}
                responseMessage={utmData}
                />
        </div>
    );

}


export default LoginView;