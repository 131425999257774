
import React from "react";
import {Link} from "react-router-dom"; 
import PieGraph from "./PieGraph";
import {ReactComponent as Arrow} from '../images/grey-arrow.svg';

  const StatusCard = (props) => {
        if(props.sitemap && props.path){
            return(
                <div 
                    className="status-card sitemap-card" 
                   // href={props.path}  
                    onClick={() => {
                        props.toggleModal();
                        props.setModalClass('image-modal');
                        props.upstreamToModal(props.path);
                    }}
                >
                    <div className="img-wrap"></div>
                    <div className="card-text">
                        <h2 className="heading-3">View Sitemap</h2>
                    </div>
                    <Arrow className="arrow-svg" />
                </div>
            )
      }

      if(props.path){
          return (
            <Link className="status-card" to={props.path}>
                <PieGraph 
                    graphValues={props.graphValues}
                    status={props.status}
                />
                <div className="card-text">
                    <h2 className="heading-3">{props.cardTitle}</h2>
                    <p>{props.subTitle}</p>
                </div>
                <Arrow className="arrow-svg" />
            </Link>
          )
    }

    // if not clickable (no props.path attribute)
    return (
        <div className="status-card with-pie-chart">
            <PieGraph 
                graphValues={props.graphValues}
                status={props.status}
            />
            <div className="card-text">
                <div className="tw-left">
                    {props.cardTitle}
                </div>
                <div className="tw-right">
                    <p>{props.subTitle}</p>
                    <p>{props.subTitle2}</p>
                </div>

                
            </div>
        </div>
    );
  };
  export default StatusCard;