import React from "react";
import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

    
function LoggedInTopBar(props){

    return(
        <div className="logged-in-top-bar">
            <div className="section-columns">
                <p className={`location-status ${props.locationID !== 0 ? 'location-specific' : ''}`}>
                    {props.locationID === 0 ? '' : <span><span className="back-to-all" onClick={() => {props.dashSiteUpdate(0); }}>All Sites</span> / </span> }
                    {props.location}
                </p>
                <div className="notification-status-wrap">
                    <LottieAnimation 
                        lotti={statusDot} 
                        height={`auto`} 
                        width={`100%`} 
                        classProp={`status-circle ${props.status === 100 ? 'green' : 'red'}`} 
                        playStatus={props.lottiePlayStatus}
                    />
                    {/* <p className="system-status">{props.systemStatus}</p> */}
                    <p className="system-status">System Status</p>
                    <div className="notifications-toggle" onClick={props.handleBellClick}>
                        <NotificationsNoneIcon className="bell-icon" />
                        {props.notificationsCount >= 1 &&
                            <div className="count">{props.notificationsCount}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoggedInTopBar;

