import React from 'react';

function APIMessageModal(props){

    return(
        <div className="api-message-modal">
            <p>{props.responseMessage}</p>
        </div>
    )
}

export default APIMessageModal;