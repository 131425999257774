import React from "react";
import Lottie from "react-lottie";

export default function LottieAnimation({ lotti, width, height, classProp, playStatus}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lotti,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className={classProp}>
        <Lottie 
          options={defaultOptions} 
          height={height} 
          width={width}  
          isStopped={playStatus ? false : true}
          //isPaused={this.state.isPaused}
        />
    </div>
  );
};