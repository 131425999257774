import React from "react";

function Loading(){
    return(
        <div className="loading-wrap">
            <span className="loading"></span>
        </div>
    )
}

export default Loading;