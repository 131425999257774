import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import DashboardCard from './DashboardCard';
import SiteCard from './SiteCard';


function DashboardView(props){
    const API = "http://35.209.149.95:8080";
    
    const [dashData, setdashData] = useState(null);
    const [userName, setuserName] = useState(null);
    const didMount = useRef(false);
   
    const baseURL = "/api/ac/dashboard";
    const MyAccountURL = "/api/ac/myaccount";
    
    // Grab API Data and set state on dashData
    useEffect(() => {
        props.setreloadingData(true);
       if(props.loginStatus){
            axios.get(baseURL).then((response) => {
                setdashData(response.data);
                props.setreloadingData(false);
            }).catch(function(error){
                props.userLogout();
            });
            axios.get(MyAccountURL).then((response) => {
                setuserName(response.data.first_name);
            }).catch(function(error){
                props.userLogout();
            });
       }
       else {
            props.userLogout();
       }
    }, []);

    if(!dashData || !userName) return null;

    const dashHealth = dashData.dashboard_health;
    const sites = dashData.sites;

    return(
        <div className="dashboard-view logged-in-content-wrap">
            <h1 className="view-heading">Welcome back, {userName}.</h1>
            <div className="status-cards-list">

            <DashboardCard
                    cardTitle="Networks"
                    icon="networks"
                    status={(dashHealth.network_health.active / dashHealth.network_health.available)} 
                    firstMetric={dashHealth.network_health.active}
                    secondMetric={dashHealth.network_health.available}
                    subTitle="networks working properly" 
                    path="/network-health" 
                />
                
                <DashboardCard 
                    cardTitle="System Status"
                    icon="system"
                    status={(dashHealth.system_health.active / dashHealth.system_health.available)} 
                    firstMetric={dashHealth.system_health.active}
                    secondMetric={dashHealth.system_health.available}
                    subTitle="nodes working properly" 
                    path="/status" 
                />
                
                

                <DashboardCard 
                    cardTitle="Radios"
                    icon="radios"
                    status={(dashHealth.radios.active / dashHealth.radios.available)} 
                    firstMetric={dashHealth.radios.active}
                    secondMetric={dashHealth.radios.available}
                    subTitle="online" 
                    path="/ran-view" 
                />

                <DashboardCard 
                    cardTitle="Subscribers"
                    icon="subscribers"
                    status={(dashHealth.subscribers.active / dashHealth.subscribers.connected)}  
                    statusColor="green"
                    firstMetric={dashHealth.subscribers.active}
                    secondMetric={dashHealth.subscribers.connected}
                    subTitle="online"
                    path="/subscribers" 
                />
            </div>
            {props.activeLocation === 0 &&
                <div className="sites-section">
                    <h2 className="heading-2">Sites</h2>
                    <div className="site-items-list">
                    {sites.map((site) => (
                        <SiteCard 
                            key={`dashsite-${site.id}`} 
                            cardTitle={site.name} 
                            zones={site.number_of_zones} 
                            radios={site.number_of_radios} 
                            activeSubs={site.number_of_active_subscribers} 
                            siteID={site.id}
                            dashSiteUpdate={props.dashSiteUpdate}
                            />
                    ))}
                    </div>
                </div>
            }
        </div>
    );
}


export default DashboardView;