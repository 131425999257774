import React, {useEffect, useState} from "react";
import axios from 'axios';
import NetworkHealthCard from "./NetworkHealthCard";

function NetworkHealth(props){
    const [networkLocations, setnetworkLocations] = useState(null);
    // Using newly provided URI
    // http://35.202.59.201:8080/api/ac/network-health
    const baseURL = "/api/ac/network-health";

    // Grab API Data and set state on networkLocations
    useEffect(() => {
        props.setreloadingData(true);
        axios.get(baseURL).then((response) => {
            setnetworkLocations(response.data.networks);
            props.updateLottiePlay();
            props.setreloadingData(false);
        }).catch(function(error){
            props.userLogout();
        });
    }, []);

    //Get the specific network ID when the view details button is clicked
    const clickNetworkDetails = (siteID) => {
        //Get the specific network data based off the returned ID
        const networkData = networkLocations.find(x => x.id === siteID);
        //Send the specific networks data back upstream to be used for detail component in drawer
        props.childToParent(networkData);
        //Set the drawers class for styling purposes
        props.setDrawerClass('network-health-detail-drawer');
        //Open the drawer
        props.drawerToggle();
    }

    if (!networkLocations) return null;

    return(
        <div className="network-health-view">
            <h1 className="view-heading">Networks</h1>
            <p>List of networks, their configurations and performance. View only.</p>
            <div className="network-health-lisiting">
            {networkLocations.map((network) => (
                <NetworkHealthCard 
                    key={network.id} 
                    siteData={network} 
                    handleDetailsClick={clickNetworkDetails}
                    lottiePlayStatus={props.lottiePlayStatus}
                />
            ))}
            </div>
        </div>
    );
}


export default NetworkHealth;