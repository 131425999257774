import React, {useState, useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { DataGridPro,
  GridToolbarContainer,
  useGridApiRef,
  NoResultsOverlay,
  GridRowsProp,
  GridToolbarFilterButton,
  gridFilterModelSelector } from '@mui/x-data-grid-pro';
import { Box, Chip } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function QuickSearchToolbar(props) {
  return (
    <GridToolbarContainer className={`grid-toolbar `}>
      <div className="filters-and-chips">
        <GridToolbarFilterButton 
          className="filter-button"
          text="Filter"
        />
        
        <Box key={props.renderKey} className="chips-wrap">
        {   props.selectedFilters.items ?
            props.selectedFilters.items.map((filter) => {
                if(filter.columnField && filter.value) return(
                    (
                        <Chip
                            key={filter.id}
                            id="report-chip"
                            label={<span>
                              <span className="filter-label">{filter.columnField}</span> 
                              {`: ${filter.value}`}
                              </span> 
                            }
                            onDelete={() => props.deleteFilter(filter)}
                            
                        /> 
                      )
                )
            }) : null} 
        </Box>
      
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className="filter-grid-search"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </GridToolbarContainer>
  );
}

// QuickSearchToolbar.propTypes = {
//   clearSearch: PropTypes.func.isRequired,
//   onChange: PropTypes.func.isRequired,
//   value: PropTypes.string.isRequired,
//   selectedFilters: PropTypes.object.isRequired,
//   deleteFilter: PropTypes.func.isRequired
// };

export default function QuickFilteringGrid(props) {
    const defaultFilterModel = {
        "items": [],
        "linkOperator": "and"
    }
  const [pageSize, setPageSize] = React.useState(5);
  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(props.rows);
  const [filters, setfilters] = React.useState({items: []});
  const [selectedFilters, setselectedFilters] = React.useState(defaultFilterModel);
  const [renderKey, setrenderKey] = React.useState(0);
  const [render, setrender] = useState(false);

  useEffect(() => {
    setrender(!render);
  }, [props.subscribers]); 

  
  const filtersName = props.filterNames;
  
    const gridAPI = useGridApiRef();
  
  const deleteFilter = (obj) => {

    let filterModel = selectedFilters;
    
    if (filterModel.items.length) {
    
      filterModel.items = filterModel.items.filter(
        (item) => item.value !== obj.value
      );
      
    }
   // setselectedFilters(filterModel);
    selectedFiltersFunc(filterModel);
  };

  const selectedFiltersFunc = (filterModel) => {
    const selectedFilters2 = defaultFilterModel;


    selectedFilters2.linkOperator = filterModel.linkOperator;

    if (filterModel.items.length) {
      
      filterModel.items.forEach((filter) => {
        selectedFilters2.items.push(filter); 
      });
      
    } 
    setselectedFilters(selectedFilters2);
      setrenderKey(Math.random());
    
  };

  const filterModelSelector = () => {
    const filterModel = gridFilterModelSelector();

    return filterModel;
  };

  const requestSearch = (searchValue) => {
  
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = props.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        if(row[field]){
          return searchRegex.test(row[field].toString());
        }
        
      });
    });
    setRows(filteredRows);
  };

  const onFilterChange = (filterModel) => {
  
    setfilters({ ...filters });

    if (filterModel.items.length) {
        setselectedFilters(filterModel);
    }
    selectedFiltersFunc(filterModel);
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  return (
      <DataGridPro
        className="responsive-table filterable-grid"
        components={ 
          props.filters ?{ Toolbar: QuickSearchToolbar }: null
        }
        rows={rows}
        columns={props.columns}
        onRowClick={props.handleRowClick ? (rowData) => {
         return props.handleRowClick(rowData.row)
        } : null }
        autoHeight={true} 
        checkboxSelection={false}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        pagination
        onFilterModelChange={(filterModel) => {onFilterChange(filterModel)}}
        filterMode="client"
        apiRef={gridAPI}
        filterModel={selectedFilters}
        treeData
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
            selectedFilters: selectedFilters,
            deleteFilter: deleteFilter,
            renderKey: renderKey
          }
        }}
      />
      
  );
}