import React from 'react';
// import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import {ReactComponent as Progress} from '../images/yellow-loading.svg';
import {ReactComponent as GreenCheck} from '../images/green-check.svg';
import {ReactComponent as YellowError} from '../images/yellow-error.svg';
function TicketSubmitted(props){
    return(
        <div className="sim-activation-content">
            
            {
                 props.ticketStatus === 'ticket-error' &&
                <div>
                    <div className='status-icon-wrap'>
                        <YellowError />
                    </div>
                    <h2>We encountered a problem.</h2>
                    
                    <Button 
                        // component={Link} 
                        // to="/" 
                        variant="contained" 
                        className="yellow-button site-button"
                        onClick={e => {
                            props.triggerModal()
                        }}
                    >
                        Return to Dashboard
                    </Button>
                    
                </div>
            }
            {
                props.ticketStatus === 'ticket-submitted' &&
                <div>
                    <div className='status-icon-wrap'>
                        <GreenCheck />
                    </div>
                    <h2>Your ticket has been submitted</h2>
                    
                    <Button 
                        // component={Link} 
                        // to="/" 
                        variant="contained" 
                        className="yellow-button site-button"
                        onClick={e => {
                            props.triggerModal()
                        }}
                    >
                        Return to Dashboard
                    </Button>
                </div>
            }
                       
            
        </div>
    )
}

export default TicketSubmitted;