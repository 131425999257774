import React from 'react';
import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

function SystemStatusCard(props){
    return(
        <div className="system-status-card">
            <div className="top-row">
                <h3>{props.cardTitle}</h3>
                <div className="status-dot-wrap">
                    {props.dotColor &&
                        <LottieAnimation 
                            playStatus={props.lottiePlayStatus}
                            lotti={statusDot} 
                            height={`auto`} 
                            width={`100%`} 
                            classProp={`status-circle ${props.dotColor}`} 
                        />
                    }
                    <span className="note">{props.statusNote}</span>
                </div>
            </div>
            <div className="alert-wrap">
                <Alert 
                    icon={
                        props.status === 'success' ? <CheckCircleOutlineIcon fontSize="inherit" /> : <ErrorOutline fontSize="inherit" />
                    }
                    className={`unrounded-alert ${props.status}`}
                >
                    {props.message}
                </Alert>
            </div>
        </div>
    )
}

export default SystemStatusCard;
