import React from "react";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';

function DoesNotExist(){
    return(
        <div className="error-404">
            <span className="section-label">404</span>
            <h1 className="view-heading">Page not found.</h1>
            <p>Sorry the page that you were looking for doesn't exist.</p>
            <Button 
                component={Link} 
                to="/dashboard"
                variant="contained" 
                className="yellow-button site-button"
            >
            Go to your dashboard
            </Button>
        </div>
    )
}

export default DoesNotExist;