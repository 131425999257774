import React, {useState, useEffect} from "react";
import axios from "axios";
import StatusCard from './StatusCard';
import Checkbox from '@mui/material/Checkbox';
import DataGrid from './DataGrid';
import QuickFilteringGrid from "./FilterableDataGrid";
import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
import Select from '@mui/material/Select';
import { GridCellParams } from '@mui/x-data-grid-pro';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SubscriberGroupSelect from "./SubscriberGroupSelect";
import Alert from '@mui/material/Alert';


// http://35.202.59.201:8080/api/sa/sims
const SIMsURL = "/api/sa/sims";

const subscribersFilterNames = {
    name: "Name",
    type: "Type",
    imsi: "IMSI",
    site: "Site",
    zone: "Zone",
    radio: "Radio",
    group: "Group"
}

function ConnectedDevices(props){ 
    const subscribersColumns: GridColDef[] = [
        { field: 'status', headerName: 'Status', filterable: false, 
        renderCell: (params: GridCellParams) => (
            <div className="dot-wrap">
            <LottieAnimation 
                lotti={statusDot} 
                height={`auto`} 
                width={`100%`} 
                // classProp={`status-circle ${params.row.status === 1  ? 'green' : 'red'}`}
                classProp={`status-circle ${  (params.row.isonline)   ? 'green' : 'yellow'}`} 
                playStatus={props.lottiePlayStatus}
            />
            </div>
        ), width:90, cellClassName:'status-column' },
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 140, cellClassName:'name-column'},
        { field: 'type', headerName: 'Type',  width: 80, cellClassName:'type-column' },
        { field: 'imsi', headerName: 'IMSI', flex: 1, minWidth: 190, cellClassName:'imsi-column' },
        { field: 'site', headerName: 'Site', width: 120, cellClassName:'site-column' },
        { field: 'zone', headerName: 'Zone', flex: 1, minWidth: 130, cellClassName:'zone-column' },
        { field: 'radio', headerName: 'Radio', flex: 1, minWidth:150, cellClassName:'radio-column' },
        { field: 'group', headerName: 'Group', flex: 1, minWidth:130, cellClassName:'group-column', renderCell: (params: GridCellParams) => {
            return(             
                <SubscriberGroupSelect 
                    extraClassName="group-select grid-select" 
                    groups={props.groups}
                    groupID={params.row.group}
                    subscriberID={params.row.subID}
                    showGroupAlert={showGroupAlert}
                    forceUpdateUsers={props.forceUpdateUsers}
                    toggleModal={props.toggleModal}
                    setModalClass={props.setModalClass}
                    upstreamToModal={props.upstreamToModal}
                    userLogout={props.userLogout}
                    valueByState={false}
                />
            )
        }  
    }
    ];

    const [viewData, setviewData] = useState(props.subscribers);
    const [subscribers, setsubscribers] = useState(props.subscribers.subscriber_details);
    const [activeRadios, setactiveRadios] = useState([]);
    const [sims, setsims] = useState([]);
    const [availableSims, setavailableSims] = useState([]);
    const [selectedCheckboxes, setselectedCheckboxes] = useState([]);
    const [showAlert, setshowAlert] = useState(false);

    const groups = props.groups;
    
    const showGroupAlert = () =>{
        setshowAlert(!showAlert);
    }

    const getInitialViewData = () => {
        console.log(props.subscribers.subscriber_details);
        props.setreloadingData(true);
        axios.get(SIMsURL).then((response) => {
            setsims(response.data);
            props.setreloadingData(false);
        }).catch(function(error){
            props.userLogout();
        });
    }
    

    // Grab API Data and set state on viewData
    useEffect(() => {
        getInitialViewData();
    }, []);
    

    const handleCheckbox = rowData => {
        let newArr = [...selectedCheckboxes];
        
        let simID = rowData.sim_id;
        let obj = {};
        obj.sim_id = rowData.sim_id;
        obj.selected_group_id = rowData.selected_group_id;

        if (newArr.filter(e => e.sim_id === simID).length < 1) {
            newArr.push(obj);
        } else{
            var removeIndex = newArr.map(item => item.sim_id).indexOf(simID);
            ~removeIndex && newArr.splice(removeIndex, 1);
        }
        setselectedCheckboxes(newArr);
    }

    const handleSelectChange = (id, sim_id) => event => {
        // copying the availableSims array for data/state manipulation
        let newArr = [...availableSims]; 
        let newArr2 = [...selectedCheckboxes]; 
        //Find index of specific object using findIndex method.    
        let objIndex = newArr.findIndex((obj => obj.id === id));
        let objIndex2 = newArr2.findIndex((obj => obj.sim_id === sim_id));
        //Set new value on particular Select component
        newArr[objIndex].selected_group_id = event.target.value; 
        if(newArr2.length > 0 && newArr2.filter(e => e.sim_id  === sim_id).length > 0){
            newArr2[objIndex2].selected_group_id = event.target.value; 
        }
        //update state with new data on Select Element
        setavailableSims(newArr);
        setselectedCheckboxes(newArr2);
    };

    

    const activateSIMs = event => {
        event.preventDefault();
        props.setModalClass('sim-activation-progress');
        props.toggleModal();

        const activateSimsURL = '/api/sa/sims/activate';
        
        axios
        .post(activateSimsURL, selectedCheckboxes)
        .then((response) => {
            if(response.data.status === 1){
                props.setModalClass('sim-activation-complete');
                getInitialViewData();
                props.forceUpdateUsers();
            } else{
                props.toggleModal();
                props.setModalClass('api-message-modal');
                props.upstreamToModal(response.data.message);

            }
        });
    }

    useEffect(() => {
        let subscriberData = subscribers;
        let activeRadiosListing = [];
        var i ;
        let idCount = 0;
        for(i=0; i < subscriberData.length; i++){
            idCount++;
            let obj = {};
            obj.id = idCount;
            obj.subID = subscriberData[i].id;
            obj.status = subscriberData[i].status;
            obj.name = subscriberData[i].name;
            obj.type = subscriberData[i].type;
            obj.imsi = subscriberData[i].imsi;
            obj.site = subscriberData[i].site;
            obj.zone = subscriberData[i].zone;
            obj.radio = subscriberData[i].cell_id;
            obj.group = subscriberData[i].group.id;
            obj.isonline = (subscriberData[i].ip_address != null);
        activeRadiosListing.push(obj);   
        }
        setactiveRadios(activeRadiosListing);
    }, [props.subscribers]);

    useEffect(() => {
        let simData = sims;
        let availableSIMsListing = [];
        let i;
        for(i=0; i < simData.length; i++){ 
            let obj = {};
            obj.id = simData[i].id;
            obj.sim_id = simData[i].sim_id;
            obj.imsi = simData[i].imsi;
            obj.rules = simData[i].rules;
            obj.rule_selected = groups[0].name;
            obj.selected_group_id = groups[0].id;
            availableSIMsListing.push(obj);
        }

        setavailableSims(availableSIMsListing);
    }, [sims]);

   
    const availableSimsColumns: GridColDef[] = [
        { field: 'select', type: 'select', headerName: 'Select', width:95, flex:0.25, cellClassName:'select-column', 
        renderCell: (params: GridCellParams) => (
            <Checkbox 
                className="green-checkbox"
                onChange={e => {
                   handleCheckbox(params.row);
                }}
            />
        ) 
        },
        { field: 'sim_id', headerName: 'SIM ID', minWidth:245, flex: 1, cellClassName:'sim-id-column'},
        { field: 'imsi', headerName: 'IMSI', minWidth:245, flex: 1, cellClassName:'imsi-column'},
        { field: 'rule', headerName: 'Group', minWidth:180, flex: 1, cellClassName:'rule-column',
        renderCell: (params: GridCellParams) => {
            return(
                <Select 
                    className="group-select grid-select full-width" 
                    size="small"
                    onChange={ handleSelectChange(params.row.id, params.row.sim_id)}
                    value={params.row.selected_group_id}
                >
                    {groups.map((group) => {
                        return(
                            <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                        );
                    })}
                </Select >
            )
        } }
    ]


    const subscribersRowClick = (subscriberRowData) => {
        //Get specific subscriber ID based off rowData
        const subscriberID = subscriberRowData.subID;

        //Get the specific subscriber from the subscriber row's ID
        const subscriberObj = subscribers.find(x => x.id === subscriberID);
  
        //Pass subscriber data back upstream
        props.childToParent(subscriberObj);
        
        props.setDrawerClass('clear-drawer');
        
        //Set the class on the drawer to be used for styling and props to determine child component
        setTimeout(function(){
            props.setDrawerClass('subscriber-detail-drawer');
            props.drawerToggle();
        },10)
    }

    
    if (!viewData || !availableSims) return null;
    return(
        <div className="connected-devices">
            <h1 className="view-heading">Subscribers</h1>
            <p>List of active subscribers and available SIMs. View and manage subscribers and SIMs.</p>
            <div className="status-cards-row">
                <StatusCard status="green" 
                graphValues={
                    [
                        { argument:'Offline', value: viewData.offline_devices},
                        { argument:'Online', value: viewData.online_devices }
                    ]
                } 
                cardTitle={viewData.online_devices} 
                subTitle={`of ${viewData.online_devices + viewData.offline_devices}`}  
                subTitle2="subscribers online"
                />
                <StatusCard 
                    status="green" 
                    graphValues={[
                            { argument:'Offline', value:viewData.available_sims - viewData.active_sims },
                            { argument:'Available', value:viewData.available_sims }
                        ]
                    } 
                    cardTitle={viewData.active_sims} 
                    subTitle={`of ${viewData.available_sims}`} 
                    subTitle2="SIMs activated"
                />
            </div>
            <div className="subscribers-section">
            <h2>Subscriber Details</h2>
            <QuickFilteringGrid 
                rows={activeRadios} 
                columns={subscribersColumns}
                filters={true}
                filterNames={subscribersFilterNames}
                handleRowClick={subscribersRowClick}
                subscribers={props.subscribers.subscriber_details}
                
            />
            {showAlert &&
                <Alert className="group-update-alert" onClose={() => {showGroupAlert()}}>Group update was successful.</Alert>
            }
            </div>
            {availableSims &&
                <div className="available-sims-section">
                    <div className="top-row">
                        <h2>Available SIMs: {availableSims.length}</h2>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            className="site-button"
                            onClick={activateSIMs}
                            disabled={selectedCheckboxes.length > 0 ? false : true}
                        >
                        Activate SIM
                        </Button>
                    </div>
                    <DataGrid 
                        rows={availableSims} 
                        columns={availableSimsColumns}
                        initialState={{
                            pinnedColumns: {
                              left: ['select']
                            }
                          }}
                    />
                    <Button 
                            type="submit" 
                            variant="contained" 
                            className="show-mobile mobile-activate-sims-btn site-button"
                            onClick={activateSIMs}
                            disabled={selectedCheckboxes.length > 0 ? false : true}
                    >
                    Activate SIM
                    </Button>
                </div>
            }
            

        </div>
    );

}


export default ConnectedDevices;