import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { BrowserRouter } from 'react-router-dom' 

LicenseInfo.setLicenseKey(
  '3202bba6c951a49c70248e3309f72190T1JERVI6Mjk2NzgsRVhQSVJZPTE2NjM3NzEzMzgwMDAsS0VZVkVSU0lPTj0x',
);
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
