import React, {useState} from 'react';
import {
    Link
   } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function ForgotPWForm(props){
    const ForgotPWURL = '/api/ua/reset';
    const [userEmail, setUserEmail] = useState('');
    const [userEmailError, setUserEmailError] = useState(false);
    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const forgotPWSubmit = event => {
     
        event.preventDefault();

        if (!isEmail(userEmail)) {
            setUserEmailError(true);
        } else{
            axios.post(ForgotPWURL, "email="+userEmail)
            .then((response) => {
               
                if(response.data.status === 1){
                    props.showForgotAlert();
                }   
            })
        }
    }

    return (
        <div className="login-form login-form-wrap forgot-password-form-wrap">
            <h1 className="view-heading">Forgot your password?</h1>
            <form onSubmit={forgotPWSubmit}>
                <div className="fields-wrap">
                    <TextField 
                        error={userEmailError ? true : false}
                        label="Primary Email" 
                        variant="outlined" 
                        className="form-field" 
                        value={userEmail}
                        onChange = {(event) => {
                            setUserEmail(event.target.value);
                            setUserEmailError(false);
                        }}
                        helperText={userEmailError ? 'Please enter a valid email address' : false}
                    />
                </div>
                <div className="form-footer">
                <Button 
                    type="submit" 
                    variant="contained" 
                    className="yellow-button site-button"
                >
                Receive a reset email
                </Button>
                <p><Link to="/">Return to login</Link></p>
                </div>
            </form>
        </div>
    
    )

}

export default ForgotPWForm;