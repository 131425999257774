import React, {useState} from "react";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';

function OrderSims(props){
    const [quantity, setquantity] = useState('');

    const simsQuantities = [ 5, 10, 15, 20, 25, 30, 35, 40]
    const handleSimsSelect = event => {
        setquantity(event.target.value);
    };


    const baseURL = '/api/sa/sims/order';

    const handleSimsForm = event => {

        event.preventDefault();
        axios
        .post(baseURL, "count="+quantity.toString())
        .then((response) => {
            if(response.data.status === 1){
                props.simsOrdered();
            } else{
                props.toggleModal();
                props.setModalClass('api-message-modal');
                props.upstreamToModal(response.data.message);
            }
            
        });
    }

    return(
        <form id="order-sims-form" onSubmit={handleSimsForm}>
                        <FormControl 
                            className="quantity-select-wrapper"
                        >
                        <InputLabel>Quantity</InputLabel>
                        <Select
                            id="sims-select"
                            onChange={handleSimsSelect}
                            label="Quantity"
                            value={quantity}
                        >
                            {simsQuantities.map((sims) => (
                                <MenuItem key={sims} value={sims}>{sims}</MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                        <Button 
                            type="submit" 
                            variant="contained" 
                            className="yellow-button site-button"
                        >
                        Submit
                        </Button>
                        </form>
    )
}

export default OrderSims;