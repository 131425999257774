import React from 'react';

function Notifications(props){

    return(
        <div>
            <h2 className="heading-3">Notifications</h2>
            {props.notificationItems.length >= 1 &&
            <div className="notifications-items">
                {props.notificationItems.map((item) => (
                    <div className="notification-item" key={item.id}>
                        <div className="note-top-row">
                            <h3 className="heading-4">{item.title}</h3>
                            <div className="clear-item" onClick={(event) => {
                                props.handleClearNotification(event);
                            }} 
                            data-remove={item.id}
                            >Clear</div>
                        </div>
                        
                        <h4 className="note-date">{item.date}</h4>
                        <div className="note-content">{item.message}</div>
                    </div>
                ))}
            </div>
            }
            {props.notificationItems.length < 1 && 
            <p>You do not have any notifications at this time.</p>
            }
        </div>
    )    
}

export default Notifications;