import React, {useState, useEffect} from 'react';
import axios from 'axios';
import FAQItem from './FAQItem';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const FAQItems = []
const FAQTopics = [];

function FAQs(props){
    const [topics, settopics] = useState(FAQTopics);
    const [activeTopic, setactiveTopic] = useState('all');
    const [faqData, setfaqData] = useState(null);

    // http://35.202.59.201:8080/api/ac/faq
    const baseURL = "/api/ac/faq";

    // Grab API Data and set state on dashData
    useEffect(() => {
        axios.get(baseURL).then((response) => {

            response.data.topics.map((item) => {
                        let topic = item.name;
                        if(!FAQTopics.includes(topic)){
                            FAQTopics.push(topic);
                        }
                        item.faq_list.map((item) => {

                            let faqObj = {
                                "question" : item.question,
                                "answer" : item.answer,
                                "topic" : topic
                            }
                            FAQItems.push(faqObj);
                        })
                        setfaqData(FAQItems);
                    })

                    settopics(FAQTopics);
                    
        }).catch(function(error){
            props.userLogout();
        });
            
    }, []);

     const handleTopicSelect = event => {
        setactiveTopic(event.target.value);
    }
   if(!faqData || !topics) return null;
    return(
        <div className="faqs-section">
            <div className="section-top-row">
            <h2>Frequently asked questions</h2>
                <FormControl 
                    className="topic-filter-wrap"
                >
                <InputLabel>Filter by topic</InputLabel>
                <Select
                    id="faq-topic-select"
                    onChange={handleTopicSelect}
                    label="Filter by topic"

                >
                    <MenuItem key={0} value={`all`}>All Topics</MenuItem>
                    {topics.map((topic, i) => (
                        <MenuItem key={i+1} value={topic}>{topic}</MenuItem>
                    ))}
                </Select>
                </FormControl>
            </div>
            <div className="faq-items">
                { activeTopic === 'all' ?
                    FAQItems.map((item, index) => (
                        <FAQItem 
                            key={index}
                            question={item.question}
                            answer={item.answer}
                            category={item.topic}
                        />
                    ))

                    :
                    FAQItems.filter(function (item) {
                        return item.topic === activeTopic;
                    }).map((item) => (
                        <FAQItem 
                            question={item.question}
                            answer={item.answer}
                            category={item.topic}
                        />
                    ))

                    
                
                }
          
            </div>
        </div>
    )
}

export default FAQs;