import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQItem(props){
    return(
        <Accordion className="faq-item">
            <AccordionSummary 
                className="faq-item-top"
                expandIcon={<ExpandMoreIcon />}
            >
                <div className="top-section">
                    <h3>{props.question}</h3>
                    <p className="faq-topic section-label">{props.category}</p>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div dangerouslySetInnerHTML={{__html: props.answer}} />
            </AccordionDetails>
        </Accordion>  
    )
}

export default FAQItem;