
import React from "react";
import {ReactComponent as Arrow} from '../images/grey-arrow.svg';

  const SiteCard = (props) => {
    return (
        <div className="site-card" onClick={() => {props.dashSiteUpdate(props.siteID); }}>
            <div className="card-text">
                <h3 className="heading-4">{props.cardTitle}<Arrow className="arrow-svg show-tablet-portrait" /></h3>
                <p className="site-details">
                    {props.zones}{props.zones >= 2 ? ' zones' : ' zone'}
                &nbsp;|&nbsp;{props.radios}{props.radios >= 2 ? ' radios' : ' radio'}
                &nbsp;|&nbsp;{props.activeSubs}{props.activeSubs >= 2 ? ' active subscribers' : ' active subscribers'}
                </p>
            </div>
            <Arrow className="arrow-svg hide-tablet-portrait" />
        </div>
    );
  };
  export default SiteCard;