import React from 'react';

function FailedLoginModal(props){

    return(
        <div className="failed-login-modal">
            <p>You have entered an incorrect email or password. Please try again. Contact your account representative for additional help.</p>
        </div>
    )
}

export default FailedLoginModal;