import React, {useEffect, useRef, useState} from 'react';
import axios from "axios";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

function SubscriberGroupSelect(props){
    const [subGroup, setsubGroup] = useState(props.groupID);
    
    const didMount = useRef(false);

    const updateGroupURL = "/api/sa/subscriber/group/update";



    // useEffect(() => {
    //     // Return early, if this is the first render:
    //     if ( !didMount.current ) {
    //         return didMount.current = true;
    //     }

    //     axios.post(updateGroupURL, 'subscriber_id='+props.subscriberID+'&group_id='+subGroup).then((response) => {
    //         if(props.showGroupAlert){
    //             props.showGroupAlert();
    //         }
    //         if(response.data.status === 1){
    //             props.forceUpdateUsers();
    //         } else{
    //             props.toggleModal();
    //             props.setModalClass('api-message-modal');
    //             props.upstreamToModal(response.data.message);
    //         }
    //     }).catch(function(error){
    //         props.userLogout();
    //     });  
    // }, [subGroup]);
    const submitGroupChange = (value) =>{
        axios.post(updateGroupURL, 'subscriber_id='+props.subscriberID+'&group_id='+value).then((response) => {
            if(props.showGroupAlert){
                props.showGroupAlert();
            }
            if(response.data.status === 1){
                props.forceUpdateUsers();
            } else{
                props.toggleModal();
                props.setModalClass('api-message-modal');
                props.upstreamToModal(response.data.message);
            }
        }).catch(function(error){
            props.userLogout();
        });
    }

    if(!subGroup && subGroup !== 0) return false;
    return(
        <FormControl 
            className={`group-select-wrapper`}
        >
        <Select
                value={props.valueByState === true ? subGroup : props.groupID}
                className={`group-select-wrapper ${props.extraClassName}`} 
                size="small"
                onChange={(event) => {
                    submitGroupChange(event.target.value);
                    setsubGroup(event.target.value);
                }
            }
        >
        {props.groups.map((group) => {
            return(
                <MenuItem key={group.id} value={parseInt(group.id)}>{group.name}</MenuItem>
            );
        })}
        </Select>
        </FormControl>
    )
}

export default SubscriberGroupSelect;