
import React from "react";
import {Link} from "react-router-dom"; 
import {ReactComponent as Arrow} from '../images/grey-arrow.svg';
import { HealthAndSafety, 
    SystemSecurityUpdateGood,
    SettingsInputAntenna,
    DevicesOther,
    Policy,
    Support,
    AccountCircle,
    Logout } from '@mui/icons-material';
import { useGridProcessedProps } from "@mui/x-data-grid-pro";

  const DashboardCard = (props) => {
          return (
            <Link className="dashboard-card" to={props.path}>
                <div className="card-top">
                    <h2 className="heading-5">{props.cardTitle}</h2>
                    {props.icon === "system" &&
                        <SystemSecurityUpdateGood />
                    }
                    {props.icon === "networks" &&
                        <HealthAndSafety />
                    }
                    {props.icon === "radios" &&
                        <SettingsInputAntenna />
                    }
                    {props.icon === "subscribers" &&
                        <DevicesOther />
                    }
                </div>
                <div className="card-text">
                        <div className="first-row"><span className="first-metric">{props.firstMetric}</span><p>&nbsp;of {props.secondMetric}</p></div>
                        <p className="second-row">{props.subTitle}</p>
                </div>
                <Arrow className="arrow-svg" />
                <div className={`status-bar ${(props.status >= 1 || props.statusColor === "green")? 'green' : 'orange'}`}>
                    <div className="status" style={{width: props.status * 100 + '%'}}></div>
                </div>
            </Link>
          )
}

  export default DashboardCard;