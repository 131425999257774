import React from 'react';
import PieGraph from "./PieGraph";

function NetworkHealthDetail(props){
    let inactiveUsers = props.siteData.number_of_connected_subscribers - props.siteData.number_of_active_subscribers;
    let usersStatus = (props.siteData.number_of_active_subscribers / props.siteData.number_of_connected_subscribers) * 100;
 
    let userGraphValues = [
                         { argument:'Inactive', value:inactiveUsers },
                         { argument:'Active', value:props.siteData.number_of_active_subscribers }
                     ]
     
     let inactiveRadios = props.siteData.number_of_available_radios - props.siteData.number_of_active_radios;
     let radiosStatus = (props.siteData.number_of_active_radios / props.siteData.number_of_available_radios) * 100;
     let radioGraphValues = [
                         { argument:'Inactive', value:inactiveRadios },
                         { argument:'Active', value:props.siteData.number_of_active_radios }
                     ]
    let siteData = props.siteData;
    let maxUplink =  siteData.uplink === 0? 'Unlimited':siteData.uplink + 'Mbps';
    let maxDownlink =  siteData.downlink === 0? 'Unlimited':siteData.downlink + 'Mbps';
    return(
        <div className="network-health-detail">
            <h2 className="detail-title">{props.siteData.name} Network Details <span>Read Only</span></h2>
            <div className="subscribers-online-details">
                <div className="tw-left">
                    {props.siteData.number_of_active_subscribers}
                </div>
                <div className="tw-right">
                    {/* <p>{`of ${props.siteData.number_of_connected_subscribers}`}</p> */}
                    <p>active sessions</p>
                </div>
            </div>
            {/* <div className="pie-graphs-wrap">
                   <div className="pie-graph-item">
                        <PieGraph 
                            graphValues={userGraphValues}
                            status={usersStatus >= 50 ? 'green' : 'red'}
                        />
                        <div className="text-wrap">
                            <h3>Users</h3>
                            <p>{`${props.siteData.number_of_active_subscribers} of ${props.siteData.number_of_connected_subscribers} online`}</p>
                        </div>
                    </div>
                    <div className="pie-graph-item">
                        <PieGraph 
                            graphValues={radioGraphValues}
                            status={radiosStatus >= 50 ? 'green' : 'red'}
                        />
                        <div className="text-wrap">
                            <h3>Radios</h3>
                            <p>{`${props.siteData.number_of_active_radios} of ${props.siteData.number_of_available_radios} online`}</p>
                        </div>
                    </div>
               </div> */}
               <ul className="network-detail-specifics">
                    <li><span>Use DHCP for Users: </span>{props.siteData.dhcp ? 'yes' : 'no'}</li>
                    <li><span>MTU: </span>{props.siteData.mtu}</li>
                    <li><span>Primary DNS: </span>{props.siteData.primary_dns}</li>
                    <li><span>Secondary DNS: </span>{props.siteData.secondary_dns}</li>
                    <li><span>Primary IPV6 DNS: </span>{props.siteData.primary_ipv6_dns}</li>
                    <li><span>Secondary IPV6 DNS: </span>{props.siteData.secondary_ipv6_dns}</li>
                    <li><span>IPV4 Pool: </span>{props.siteData.ipv4_pool}</li>
                    <li><span>IPV6 Pool: </span>{props.siteData.ipv6_prefix_pool}</li>
                    <li><span>Allow Multiple: </span>{props.siteData.allow_multiple ? 'yes' : 'no'}</li>
                    <li><span>QCI: </span>{props.siteData.qci}</li> 
                    <li><span>Priority: </span>{props.siteData.priority} (1–15)</li> 
                    {/* <li><span>Max Uplink: </span>{props.siteData.uplink === 0? 'Unlimited': `${props.siteData.uplink}Mbps`}</li> */}
                    <li><span>Max Uplink: </span>{maxUplink}</li>
                    <li><span>Max Downlink: </span>{maxDownlink}</li> 
                    {/* <li><span>Max Bitrate: </span>{props.siteData.bitrate}Mbps</li>                  */}
                    </ul>
        </div>
    )
}

export default NetworkHealthDetail;