import React from 'react';
import {Link} from 'react-router-dom';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
// import LottieAnimation from './Lottie';
// import statusDot from '../lottie/status-dot.json';
import {ReactComponent as Arrow} from '../images/grey-arrow.svg';
import {ReactComponent as Locked} from '../images/locked.svg';
import {ReactComponent as Unlocked} from '../images/unlocked.svg';

function RanZoneDetail(props){
    const zoneData = props.zoneData.zoneDataObj;
    return(
        <div className="ran-zone-detail-content">
            <h2 className="zone-title">
                <span className="lighter">{props.zoneData.siteName} / </span>
                 <span className="darker">
                    {zoneData.name}
                    {(zoneData.access_status === true) &&
                        <Unlocked className="lock-icon" />
                    }
                    {(zoneData.access_status === false) &&
                        <Locked className="lock-icon" />
                    }
                 </span>
                
            </h2>
            <div className="subscribers-and-rules">
                <div className="subscribers-col">
                    <h3>Subscribers</h3>
                    <div className="subscribers-online">
                        {zoneData.subscribers_connected} <span>online</span>
                    </div>
                    <Link 
                        className="arrow-link" 
                        to='/subscribers'
                        onClick={props.drawerToggle}
                    >
                        View Subscribers
                        <Arrow />
                    </Link>
                </div>
                <div className="groups-column">
                    <h3>Groups assigned to this zone</h3>
                    <div className="groups-list">
                        {zoneData.groups.map((group) => (
                        <Chip className="group-chip" key={group.id} label={group.name} />
                        ))}
                    </div>
                    
                </div>
            </div>
            <div className="radios-listing">
                <h3>Radios</h3>
                {zoneData.radios.alarm_message &&
                    <Alert 
                    //     icon={
                    //         props.status === 'success' ? <CheckCircleOutlineIcon fontSize="inherit" /> : <ErrorOutline fontSize="inherit" />
                    //     }
                    //     className={`unrounded-alert ${props.status}`}
                    // >
                    //     {props.message}
    
                    className={`unrounded-alert ${zoneData.status !== 100 ? 'error' : ''}`}
                    icon={<ErrorOutline fontSize="inherit" />}
                    >
                      {zoneData.radios.alarm_message}
                    </Alert>
                }
                
                <div className="radios-items">
                {zoneData.radios.radios.map((radio) => (
                    <div key={radio.id} className="radio-item-card">
                        <div className="status" style={{backgroundColor: `#${radio.status_color}`}}>{radio.status}</div>
                        {/* <LottieAnimation 
                            lotti={statusDot} 
                            height={`auto`} 
                            width={`100%`} 
                            classProp={`status-circle ${radio.status === 'ONLINE' ? 'green' : 'red'}`} 
                            playStatus={props.lottiePlayStatus}
                        /> */}
                        <h5><strong>CELL ID: </strong>{radio.cell_id}</h5>
                        <h5><strong>TAI: </strong>{radio.tai}</h5>
                        {/* <h5><strong>BANDW: </strong>{radio.uplink}Mbps | {radio.downlink}Mbps</h5> */}
                    </div>
                ))}
                </div>
            </div>
        </div>
    )
}

export default RanZoneDetail;