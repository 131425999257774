import React, {useEffect, useRef} from 'react';
import axios from "axios";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {Typography } from "@material-ui/core";
import SubscriberGroupSelect from "./SubscriberGroupSelect";
import ModeEditIcon from '@mui/icons-material/ModeEdit';


function SubscriberDetail(props){
    const [switchState, setswitchState] = React.useState(props.subscriberData.status);
    const [isEditing, setIsEditing] = React.useState(false);
    const [username, setusername] = React.useState(props.subscriberData.name);
    
    const didMount = useRef(false);
    const didMount2 = useRef(false);

    const updateSubscriberURL = "/api/sa/subscriber/status/update";
    const updateNameURL = "/api/sa/subscriber/name/update";

    const toggleIsEditing = () => setIsEditing((b) => !b);

    const handleSwitchChange = () =>{
        setswitchState(!switchState);
    }

    useEffect(() => {
          // Return early, if this is the first render:
        if ( !didMount.current ) {
            return didMount.current = true;
        }

        axios.post(updateSubscriberURL, `subscriber_id=${props.subscriberData.id}&status=${switchState ? 1 : 0}`).then((response) => {
            if(response.data.status === 1){
                props.forceUpdateUsers();
            } else{
                props.toggleModal();
                props.setModalClass('api-message-modal');
                props.upstreamToModal(response.data.message);
            
            }
            
        }).catch(function(error){
            props.userLogout();
        });  
    }, [switchState]);

    const updateUsername = (event) => {
        setusername(event.target.value);
    }

    useEffect(() => {
        // Return early, if this is the first render:
      if ( !didMount2.current ) {
          return didMount2.current = true;
      }

      
    }, [username]);

    const submitUsername = () => {
        axios.post(updateNameURL, 'subscriber_id='+props.subscriberData.id+'&name='+username).then((response) => {
          
            if(response.data.status === 1){
                props.forceUpdateUsers();
            } else{
                props.toggleModal();
                props.setModalClass('api-message-modal');
                props.upstreamToModal(response.data.message);
            }
        }).catch(function(error){
            props.userLogout();
        });  
    }

    

    if(!username) return false;
    return(
        <div className="subscriber-detail">
            {isEditing &&
                 <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                    className=""
                    value={username}
                    onChange={updateUsername}
                    />
                    <Typography style={{ display: "none" }} />
                    <ModeEditIcon 
                        onClick={() => {
                            toggleIsEditing();
                            submitUsername();
                        }}
                        className="edit-icon"
                    />

                 </div>
            }
            {!isEditing &&
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography display="inline">
                        <span className="view-heading">{username}</span>
                    </Typography>

                        <ModeEditIcon 
                            onClick={toggleIsEditing} 
                            className="edit-icon"
                        />

                </div>
            }

           <div className="detail-columns">
                <div className="columns-row">
                    <div className="left-col">
                        <h3>SIM Status</h3>
                        <FormGroup
                            defaultValue={props.subscriberData.device_status}
                        >
                            <FormControlLabel  control={<Switch onChange={handleSwitchChange}  checked={switchState} className="green-switch" />} label="Activated" />
                        </FormGroup>

                    </div>
                    <div className="right-col">
                        <h3>Group</h3>
                        <SubscriberGroupSelect 
                            groups={props.groups}
                            groupID={props.subscriberData.group.id}
                            subscriberID={props.subscriberData.id}
                            forceUpdateUsers={props.forceUpdateUsers}
                            toggleModal={props.toggleModal}
                            setModalClass={props.setModalClass}
                            upstreamToModal={props.upstreamToModal}
                            userLogout={props.userLogout}
                            valueByState={true}
                        />
                    </div>
                </div>
                <div className="columns-row type-and-site">
                    <div className="left-col">
                        <h3 className="section-label">Type</h3>
                        <h4>{props.subscriberData.type}</h4>
                    </div>
                    <div className="right-col">
                        <h3 className="section-label">Site</h3>
                        <h4>{props.subscriberData.site}</h4>
                    </div>
                </div>
                <div className="columns-row">
                    <div className="left-col">
                        <h3 className="section-label">Cell</h3>
                        <h4>{props.subscriberData.cell_id}</h4>
                    </div>
                    <div className="right-col">
                        <h3 className="section-label">Zone</h3>
                        <h4>{props.subscriberData.zone}</h4>
                    </div>
                </div>
                <div className="columns-row">
                    <div className="left-col">
                        <h3 className="section-label">IP Address</h3>
                        <h4>{props.subscriberData.ip_address}</h4>
                    </div>
                    <div className="right-col">
                        <h3 className="section-label">SIM ID</h3>
                        <h4>{props.subscriberData.sim_id}</h4>
                    </div>
                </div>
                <div className="columns-row">
                    <div className="left-col">
                        <h3 className="section-label">IMEI</h3>
                        <h4>{props.subscriberData.imei}</h4>
                    </div>
                    <div className="right-col">
                        <h3 className="section-label">IMSI</h3>
                        <h4>{props.subscriberData.imsi}</h4>
                    </div>
                </div>
           </div>
        </div>
    )
}

export default SubscriberDetail;