import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';

// http://35.202.59.201:8080/api/ac/myaccount
const MyAccountURL = "/api/ac/myaccount";


function AddUserForm(props){
    const[firstName, setFirstName] = useState('');
    const[lastName, setLastName] = useState('');
    const[userRole, setuserRole] = useState('');
    const[userSites, setuserSites] = useState([]);
    const[email, setEmail] = useState('');
    const[errorMessage, seterrorMessage] = useState(null);
    const [formSites, setformSites] = useState(null);
    

    useEffect(() => {
            let sitesArr = [];
            let sitesData = props.sites;
            let i;
            for(i=0; i < sitesData.length; i++){
                let obj = {};
                obj.id = sitesData[i].id;
                obj.name = sitesData[i].name;
                sitesArr.push(obj);   
            }
        setformSites(sitesArr);
    }, []);

    const handleRoleChange = (event) => {
          setuserRole(event.target.value);   
      };
      
    const handleSubmit = (event) => {
        event.preventDefault();
        
        const postObj = {
                "email" : email,
                "roles" : [{"id": userRole}],
                "sites" : userSites,
                "first_name" : firstName,
                "last_name" : lastName
        }
      
        axios
        .post('/api/ua/user/new', postObj)
        .then((response) => {
            
            if(response.data.status === 1){
             //   props.setModalClass('request-process');
                props.setModalClass('success-added');
                props.upstreamToModal(response.data.message);
            } else{
                props.setModalClass('api-message-modal');
                props.upstreamToModal(response.data.message);
            }

            // if(response.status === 200){
            //     props.setModalClass('request-process');
            // } else{
            //     seterrorMessage(response.data.message);
            // }
        });
    }

    if(!formSites) return null;
    
    return(
        <div className="add-user-form">
            <h2>Add a new user</h2>
            <form onSubmit={handleSubmit}>
                <TextField 
                    label="First Name" 
                    variant="outlined" 
                    className="form-field text-field" 
                    id="first-name-field"
                    onChange={(event) => {setFirstName(event.target.value)}}
                />
                <TextField 
                    label="Last Name" 
                    variant="outlined" 
                    className="form-field text-field" 
                    id="last-name-field"
                    onChange={(event) => {setLastName(event.target.value)}}
                />
                <TextField 
                    label="Email" 
                    variant="outlined" 
                    className="form-field text-field" 
                    type="email"
                    id="email-field"
                    onChange={(event) => {setEmail(event.target.value)}}
                />
                <div className="bottom-selectors-row">
                    <FormControl size="small" >
                        <InputLabel  id="role-select-label">Select a role</InputLabel>
                        <Select
                            labelId="role-select-label"
                            id="role-select"
                            value={userRole}
                            onChange={handleRoleChange}
                            input={<OutlinedInput label="Select a role" />}
                        >
                        {props.roles.map((role) => {
                            return(
                                <MenuItem key={`role-${role.id}`} value={role.id}>{role.display_name}</MenuItem>
                            );
                        })}
                        </Select>
                    </FormControl>


                <Autocomplete
                    multiple
                    limitTags={2}
                    disableClearable={true}
                    className="form-autocomplete select-sites"
                    id="sites-select"
                    size="small"
                    options={formSites}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    defaultValue={[]}
                    onChange={(event, value) => {
                        setuserSites(value)
                    }
                    }
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                        <Chip
                            key={`site-${option.id}`}
                            label={option.name}
                        />
                        ))
                    }
                    renderInput={(params) => (
                    <TextField {...params} label="Select a site(s)" placeholder="" />
                    )}
                />
            </div>
            
            <div className="form-footer spaced">
            {errorMessage &&
                <p className="error-message">{errorMessage}</p>
            }
            <Button 
                type="submit" 
                variant="contained" 
                className="yellow-button site-button"
            >
            Submit
            </Button>
            </div>
        </form>
        </div>
    )
}

export default AddUserForm;