import React, {useEffect} from "react";
import {ReactComponent as CloseX} from '../images/close-x.svg';
import Notifications from './Notifications';
import NetworkHealthDetail from './NetworkHealthDetail';
import RanZoneDetail from './RanZoneDetail';
import SubscriberDetail from './SubscriberDetail';

import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
function Drawer(props){
    return(
        <div className={`offwindow-drawer ${props.drawerOpen ? "open " : ""} ${props.drawerType ? props.drawerType : ""}`} id="drawer">
        <div className="interior">
            <div className="drawer-top-row">
                {
                    (props.drawerType === 'notifications-drawer' && props.notificationItems.length >= 1) &&
                    <div className="clear-all" onClick={props.handleClearAll}>Clear All</div>
                }
                {
                    props.drawerType === 'ran-zone-detail-drawer' &&
                    <div className="status-detail">
                        <LottieAnimation 
                            lotti={statusDot} 
                            height={`auto`} 
                            width={`100%`} 
                            classProp={`status-circle ${props.dataPassedUp.zoneDataObj.status === 100 ? 'green' : 'red'}`} 
                            playStatus={props.lottiePlayStatus}
                            />
                        <span className="status-message">{props.dataPassedUp.zoneDataObj.status_message}</span>
                    </div>
                }
                {
                    props.drawerType === 'network-health-detail-drawer' &&
                    <div className="status-detail">
                         <LottieAnimation 
                            lotti={statusDot} 
                            height={`auto`} 
                            width={`100%`} 
                            classProp={`status-circle ${props.dataPassedUp.network_health === 100 ? 'green' : 'red'}`} 
                            playStatus={props.lottiePlayStatus}
                            />

                       {props.dataPassedUp.status_message &&
                        <span className="status-message">{props.dataPassedUp.status_message}</span>
                       } 
                    </div>
                }
                <button className="close-drawer" onClick={props.drawerToggle} >
                    <CloseX />
                </button> 
            </div>
            {  
                props.drawerType === 'clear-drawer' &&
                <div></div>
            }
            {  
                props.drawerType === 'notifications-drawer' &&
                <Notifications 
                    notificationItems={props.notificationItems}
                    handleClearNotification={props.handleClearNotification}
                />
            }
            {  
                props.drawerType === 'network-health-detail-drawer' &&
                <NetworkHealthDetail 
                    siteData={props.dataPassedUp}
                />
            }
            {
                props.drawerType === 'ran-zone-detail-drawer' &&
                <RanZoneDetail
                    zoneData={props.dataPassedUp}
                    drawerToggle={props.drawerToggle}
                    lottiePlayStatus={props.lottiePlayStatus}
                />
            }
            {
                (props.drawerType === 'subscriber-detail-drawer' && props.drawerOpen) &&
                <SubscriberDetail
                    subscriberData={props.dataPassedUp}
                    groups={props.groups}
                    drawerStatus={props.drawerOpen}
                    forceUpdateUsers={props.forceUpdateUsers}
                    toggleModal={props.toggleModal}
                    setModalClass={props.setModalClass}
                    upstreamToModal={props.upstreamToModal}
                    userLogout={props.userLogout}
                />
            }
        </div>
        
    </div>
    )


}


export default Drawer;