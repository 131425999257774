import React, {useState, useEffect} from 'react';
import {
   Link
  } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


function LoginForm(props){
    const [userName, setuserName] = useState('');
    const [password, setpassword] = useState('');
    const [userNameError, setuserNameError] = useState(false);
    const [passwordError, setpasswordError] = useState(false);
    const [invalidTermsLogin, setinvalidTermsLogin] = useState(false);
    const [remember, setremember] = useState(false);

    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);

    const handleLoginSubmit = event => {
        event.preventDefault();
        setinvalidTermsLogin(false);
        if (!isEmail(userName)) {
            setuserNameError(true);
        }
        if(password.length < 3){
            setpasswordError(true);
        }
        if(userName.length < 3 || password.length < 3){
            return;
        }
        if(props.termschecked){
            props.userLogin(userName, password, remember);
        } else{
            setinvalidTermsLogin(true);
        }
    }

    const termsModal = (event) =>{
        event.preventDefault();
        props.setModalClass('terms-and-conditions');
        props.triggerModal();
    }
    useEffect(() =>{
        if(props.termschecked){
            setinvalidTermsLogin(false);
        }
    },[props.termschecked]);

    return (
      <div className="login-form login-form-wrap">
          <h1 className="view-heading">Login to your CTS Dashboard.</h1>
          <p>Login with your credentials to view and manage your private wireless network service.</p>
          <form onSubmit={handleLoginSubmit}>
            <div className="fields-wrap">
                <TextField 
                    error={userNameError ? true : false}
                    label="Primary Email" 
                    variant="outlined" 
                    className="form-field" 
                    value={userName}
                    onChange={(event) => {
                        setuserName(event.target.value);
                        setuserNameError(false);
                    }} 
                    helperText={userNameError ? 'Please enter a valid email address' : false}
                />
                <TextField 
                    error={passwordError ? true : false}
                    label="Password" 
                    variant="outlined" 
                    type="password" 
                    className="form-field" 
                    helperText="Must be at least 8 characters long"
                    value={password}
                    onChange={(event) => {
                        setpassword(event.target.value);
                        setpasswordError(false);
                    }}
                    />
                    <p className="terms-note">By entering your user name and password above, you acknowledge that you have read, understood, and agree to be bound by and comply with these Terms.</p>
                    <FormControlLabel 
                        className={`terms-checkbox ${(invalidTermsLogin)? 'invalid' : null}`}
                        control={
                        <Checkbox 
                            id="terms-checkbox" 
                            className={`blue-checkbox`}
                            checked={props.termschecked} 
                            onChange={event => {
                                props.handletermsChange();
                            }}  
                            />} 
                            label={<div>
                            I agree to the <span onClick={termsModal}>Terms and Conditions</span>
                        </div>} 

                    />
            </div>
            
            <div className="form-footer spaced">
            <Button 
                type="submit" 
                variant="contained" 
                className="yellow-button site-button"
            >
            Login
            </Button>
            <FormControlLabel 
                control={<Checkbox className="blue-checkbox"  />} 
                label="Keep me logged in" 
                className="keep-me-logged-in"
                value={remember}
                onChange={event => {
                    setremember(!remember);
                }}
            />
            </div>
        </form>
        <div className="extra-details">
            <p>Don’t have an account? <a href="mailto:NOSC@cts1.com">Contact CTS</a></p>
            <p><Link to="/forgot-password">Forgot password?</Link></p>
        </div>
      </div>
   
  )
}

export default LoginForm;