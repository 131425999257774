import React, {useState, useEffect} from 'react';
import axios from "axios";
import OrderSims from './OrderSims';
import SupportTicketForm from "./SupportTicketForm";
import FAQs from './FAQs';

function HelpAndSupportView(props){
    const [accountMangerData, setaccountMangerData] = useState(null);
    const accountManagerURL = "/api/ac/account-manager";

    useEffect(() => {
        props.setreloadingData(true);
        axios.get(accountManagerURL).then((response) => {
            setaccountMangerData(response.data);
            props.setreloadingData(false);
        }).catch(function(error){
            props.userLogout();
        });  
    }, []);

    if(!accountMangerData) return null;
    return(
        <div className="help-and-support-view">
             
            <h1 className="view-heading">Help &amp; Support</h1>
            <p>Create a support ticket or order new SIMs. Find your CTS account manager contact.</p>
            <div className="contact-view-top">
                <div className="left-col">
                    <div className="col-top-wrap">
                        <h2>Create A Support Ticket</h2>
                    </div>
                    <SupportTicketForm 
                        userLogout={props.userLogout}
                        toggleModal={props.toggleModal}
                        setModalClass={props.setModalClass}
                        upstreamToModal={props.upstreamToModal}
                    />
                </div>
                <div className="right-col">
                    <div className="column-interior-wrapper">
                        <h2>Order More SIMs</h2>
                        <OrderSims 
                            simsOrdered={props.simsOrdered}
                            toggleModal={props.toggleModal}
                            setModalClass={props.setModalClass}
                            upstreamToModal={props.upstreamToModal}
                            userLogout={props.userLogout}
                        />
                    </div>
                    <div className="column-interior-wrapper">
                        <span className="section-label">Your account manager</span>
                        <p>{accountMangerData.name}</p>
                        <span className="section-label">Phone</span>
                        <a href={`tel:${accountMangerData.phone.replaceAll('.','')}`}>{accountMangerData.phone}</a>
                        <span className="section-label">Email</span>
                        <a href={`mailto:${accountMangerData.email}`}>{accountMangerData.email}</a>
                    </div>
                </div>
            </div>
            <FAQs  
                userLogout={props.userLogout}
            />
        </div>
    );
}

export default HelpAndSupportView;