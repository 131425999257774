import React, {useState} from 'react';
import {
   Link
  } from "react-router-dom";
import axios from "axios";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

function ResetPasswordForm(props){
    const ResetPWURL = "/api/ua/password";
    const [newPW, setnewPW] = useState('');
    const [newPW2, setnewPW2] = useState('');
    const [newPWError, setnewPWError] = useState(false);
    const [newPW2Error, setnewPW2Error] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const email = urlParams.get('email');
    const et = urlParams.get('et');
    const [userEmail, setUserEmail] = useState(email);

    const handlePWSubmit = event => {
        event.preventDefault();
        if(newPW.length < 8){
            setnewPWError(true);
        }
        if(newPW !== newPW2){
            setnewPW2Error(true);
        }
        if(newPW.length < 8 || newPW !== newPW2){
            return;
        }

        if((newPW.length >= 8 && newPW2.length >= 8) && newPW === newPW2){
            
            axios.post(ResetPWURL, "email="+userEmail+"&password="+newPW+"&et="+et,{withCredentials:true})
            .then((response) => {
                
                if(response.data.status === 1){
                    props.showPWResetAlert();
                }
            })
        }
    }
    
    return (
        <div className="login-form login-form-wrap reset-password-form-wrap">
            <h1 className="view-heading">Reset Your Password</h1>
            <form onSubmit={handlePWSubmit}>
                <div className="fields-wrap">
                    <TextField 
                    error={newPWError ? true : false}
                    label="Enter New Password" 
                    variant="outlined" 
                    type="password" 
                    className="form-field" 
                    helperText="Must be at least 8 characters long"
                    value={newPW}
                    onChange={(event) => {
                        setnewPW(event.target.value);
                        setnewPWError(false);
                    }} 
                    />
                    <TextField 
                    error={newPW2Error ? true : false}
                    label="Re-enter New Password" 
                    variant="outlined" 
                    type="password" 
                    className="form-field" 
                    helperText="Make sure this matches the previously entered password"
                    value={newPW2}
                    onChange={(event) => {
                        setnewPW2(event.target.value);
                        setnewPW2Error(false);
                    }} 
                    />
                </div>
                <div className="form-footer">
                <Button 
                    type="submit" 
                    variant="contained" 
                    className="yellow-button site-button"
                    onClick={props.resetPWClick}
                >
                Change Password
                </Button>
                <p><Link to="/">Return to login</Link></p>
                </div>
            </form>
        </div>
    
    )

}

export default ResetPasswordForm;