import React from 'react';

function EnableCookiesModal(props){

    return(
        <div className="cookies-modal">
            <p>This application requires cookies to save your logged in state. If cookies are disabled, you will be logged out when you leave this application or refresh your browser.</p>
        </div>
    )
}

export default EnableCookiesModal;