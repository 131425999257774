import React from 'react';
import LottieAnimation from './Lottie';
import statusDot from '../lottie/status-dot.json';
import {ReactComponent as Arrow} from '../images/grey-arrow.svg';
import {ReactComponent as Locked} from '../images/locked.svg';
import {ReactComponent as Unlocked} from '../images/unlocked.svg';

function RanSiteZones(props){

    const site = props.site;

    let statusMessage="System Status";
    return(
        <div className="ran-site-zones">
            <div className="ran-item-top">
                <h4 className="heading-3">
                    {site.name}
                </h4>
                <div className="site-status-wrap">
                    <div className="dot-wrap">
                    <LottieAnimation 
                        lotti={statusDot} 
                        height={`auto`} 
                        width={`100%`} 
                        classProp={`status-circle ${site.system_status !== 100 ? 'red' : 'green'}`} 
                        playStatus={props.lottiePlayStatus}
                    />
                    </div>
                    <p>{statusMessage}</p>
                </div>
            </div>
             <div className="zones-lisiting">
                {site.zones.map((zone) => {
                    return(
                        <div className="zone-row" key={zone.id}>
                            <div className="col-1 zone-col">
                                <div className="interior">
                                    <h5 className="heading-4">
                                        {zone.name}
                                        {(zone.access_status === true) &&
                                            <Unlocked className="lock-icon" />
                                        }
                                        {(zone.access_status === false) &&
                                            <Locked className="lock-icon" />
                                        }
                                        
                                    </h5>
                                    <div className="col-bottom">
                                        <div className="dot-wrap">
                                        <LottieAnimation 
                                            lotti={statusDot} 
                                            height={`auto`} 
                                            width={`100%`} 
                                            classProp={`status-circle ${zone.status !== 100 ? 'red' : 'green'}`} 
                                            playStatus={props.lottiePlayStatus}
                                        />
                                        </div>
                                        <p className="uptime">{zone.uptime}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 zone-col">
                                <div className="interior">
                                    <span className="ran-zone-label">Group</span>
                                    <div>{zone.groups.map((group, i) => {
                                        return(
                                           <p key={`group-${i}`}>{group.name}</p> 
                                        )
                                        })}</div>
                                </div>
                            </div>
                            <div className="col-3 zone-col">
                                <div className="interior">
                                    <span className="ran-zone-label">Subscribers</span>
                                    <p>{zone.subscribers_connected} Online</p>
                                </div>
                            </div>
                            <div className="col-4 zone-col">
                                <div className="interior">
                                    <span className="ran-zone-label">Radios</span>
                                    <p>{zone.radios.online}  Online</p>
                                </div>
                            </div>
                            <div className="col-5 zone-col" onClick={() => props.showZoneDetails(props.siteID, zone.id)}>
                                View Zone Details <Arrow />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default RanSiteZones;