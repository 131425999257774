
import React, {useEffect, useState} from "react";
import axios from 'axios';
import StatusCard from './StatusCard';
import RanSiteZones from "./RanSiteZones";

function RanList(props){

    const [ranList, setranList] = useState(null);

    // http://35.202.59.201:8080/api/ac/ran
    const baseURL = "/api/ac/ran";

    // Grab API Data and set state on ranList
    useEffect(() => {
        props.setreloadingData(true);
        axios.get(baseURL).then((response) => {
            
            setranList(response.data);
            props.updateLottiePlay();
            props.setreloadingData(false);
        }).catch(function(error){
            props.userLogout();
        });
    }, []);

    //When View Detail is clicked send back the site and zone IDs
    const showZoneDetails = (siteID, zoneID) =>{
        const sites = ranList.sites;

        //Get the specific site from the returned siteID
        const siteObj = sites.find(x => x.id === siteID);
        //Get Site Name
        const parentSiteName = siteObj.name;
        //Get the specific zone from the above site from the returned zoneID
        const zoneData = siteObj.zones.find(x => x.id === zoneID);
        //Build object to send back
        const dataToSendBack = {'siteName' : parentSiteName, 'zoneDataObj' : zoneData}
        //Send that zoneID back upstream to be used for the detail component (inside the drawer)
        props.childToParent(dataToSendBack);
        // set the drawer's class to manage styling
        props.setDrawerClass('ran-zone-detail-drawer');
        // open the drawer
        props.drawerToggle();
    }

    if (!ranList) return null;

    const percentConnected = (ranList.number_of_connected_subscribers / ranList.number_of_active_subscribers) * 100;
    const totalRadios = ranList.number_of_online_radios + ranList.number_of_offline_radios;
    const percentRadiosOnline = (ranList.number_of_online_radios / totalRadios) * 100;
    return(
        <div className="ran-view">
            <h1 className="view-heading">Radio Access Network</h1>
            <p>Sites and zones/radios with their access settings.</p>
            <div className="status-cards-row">
                <StatusCard 
                    status={percentRadiosOnline >= 100 ? 'green' : 'orange'} 
                    graphValues={
                        [
                            { argument:'Offline', value:ranList.number_of_offline_radios},
                            { argument:'Online', value:ranList.number_of_online_radios}
                        ]
                    } 
                    cardTitle={ranList.number_of_online_radios} 
                    subTitle={` of ${totalRadios}`}  
                    subTitle2="radios online"
                />
                <StatusCard 
                    status="green"
                    graphValues={
                        [
                            { argument:'Active', value:ranList.number_of_active_subscribers - ranList.number_of_connected_subscribers},
                            { argument:'Connected', value:ranList.number_of_connected_subscribers }
                        ]
                    } 
                    cardTitle={ranList.number_of_connected_subscribers} 
                    subTitle={` of ${ranList.number_of_active_subscribers}`} 
                    subTitle2="subscribers online"
                />
                {
                    ranList.sitemap &&
                    <StatusCard 
                        sitemap={true} 
                        path={ranList.sitemap} 
                        toggleModal={props.toggleModal}
                        setModalClass={props.setModalClass}
                        upstreamToModal={props.upstreamToModal}
                    />
                }
                {
                    !ranList.sitemap &&
                    <div className="missing-element">Sitemap key is missing from response</div>
                }
                
            </div>
            <div className="sites-section">
                <h2 className="heading-2">Your Sites</h2>
                {ranList.sites.map((site) => (
                    <RanSiteZones 
                        key={site.id} 
                        site={site} 
                        siteID={site.id} 
                        showZoneDetails={showZoneDetails} 
                        lottiePlayStatus={props.lottiePlayStatus}
                    />
                ))}
            </div>
        </div>
    );
}

export default RanList;