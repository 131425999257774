import React, { useEffect} from "react";
import axios from "axios";
import {Link} from 'react-router-dom';
import OrderSims from './OrderSims';
import DataGrid from './DataGrid';
import Select from '@mui/material/Select';
import { GridCellParams } from '@mui/x-data-grid-pro';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

function AccountView(props){

const MyAccountURL = "/api/ac/myaccount";
const SIMSCountURL = "/api/sa/sim-count";

const [accountData, setaccountData] = React.useState(null);
const [activeUsers, setActiveUsers] = React.useState([]);
const [simsCount, setsimsCount] = React.useState([]);
const [sites, setSites] = React.useState([]);
const [users, setUsers] = React.useState(null);
const [usersGridRender, setusersGridRender] = React.useState(true);

// Grab API Data and set state on accountData, sims, and users
const getInitialViewData = () => { 
    props.setreloadingData(true);
    axios.get(MyAccountURL).then((response) => {
        setaccountData(response.data);
        let sitesArr = [];
        let sitesData = response.data.sites;
        let i;
        for(i=0; i < sitesData.length; i++){
            let obj = {};
            obj.id = sitesData[i].id;
            if(sitesData[i].name){
                obj.name = sitesData[i].name;
            }
           // obj.name = sitesData[i].name;
            sitesArr.push(obj);   
        }
        setSites(sitesArr);
        props.setreloadingData(false);
    }).catch(function(error){
        props.userLogout();
    });

    axios.get(SIMSCountURL).then((response) => {
        setsimsCount(response.data);
    }).catch(function(error){
        props.userLogout();
    });
    

    setUsers(props.users);
}

    useEffect(() => {
        // Run the initial request on component mount
        getInitialViewData();
    }, []);


    useEffect(() => {
        props.setreloadingData(true);
        let activeUsersArr = [];
        let usersData = props.users;
        let i;
        if(props.users){
            
            for(i=0; i < usersData.length; i++){
                let obj = {};
                obj.id = usersData[i].id;
                obj.email = usersData[i].email;
                obj.first_name = usersData[i].first_name;
                obj.last_name = usersData[i].last_name;
                obj.name = usersData[i].first_name + ' ' + usersData[i].last_name;
                if(usersData[i].roles.length > 0){
                    obj.role = usersData[i].roles[0].name;
                }
                let j;
                let sarr = [];
                
                for(j=0; j < usersData[i].sites.length; j++){
                    let sobj2 = {};
                    sobj2.id = usersData[i].sites[j].id;
                    sobj2.name = usersData[i].sites[j].name;
                    sarr.push(sobj2);
                    
                }
                obj.currentsites = sarr;
    
                activeUsersArr.push(obj);   
            }
            setActiveUsers(activeUsersArr);
            props.setreloadingData(false);
        } else{
            setActiveUsers([]);
            props.setreloadingData(false);
        }
        
    }, [props.users]);

    

    if (!accountData) return null;
    let actualRole = '';
    
    if(accountData.roles.length){
            // Get ID of role provided in account API response
            const accountRoleID = accountData.roles[0].id;
            // Filter object from all available roles based off ID provided in account response
            // Get display name from that object
            actualRole = props.roles.filter(role => role.id === accountRoleID)[0].display_name;
    }



    const handleSelectChange = (userid, eventType, value) => {
        // copying the props.roles array for data/state manipulation
        let newArr = [...activeUsers]; 

        //Find index of specific object using findIndex method.    
        let objIndex = newArr.findIndex((obj => obj.id === userid));

        //Set new value on particular Select component
        if(eventType === 'update-role'){
            newArr[objIndex].role = value; 
        }

        //update state with new data on Select Element
        setActiveUsers(newArr);
      
        let roleObj = props.roles.filter(role1 => newArr[objIndex].role === role1.name);
        
        let newSitesArr = [];
        let i;
        for(i=0; i < newArr[objIndex].currentsites.length; i++){
            newSitesArr.push({'id': newArr[objIndex].currentsites[i].id});
        }
        let postObj = {};
        
        if(eventType === 'update-role'){
             postObj = {
                "email" : newArr[objIndex].email,
                "roles" : [{"id": roleObj[0].id}],
                "sites" : newSitesArr,
                "first_name" : newArr[objIndex].first_name,
                "last_name" : newArr[objIndex].last_name
            }
        }

        if(eventType === 'update-sites'){
            postObj = {
               "email" : newArr[objIndex].email,
               "roles" : [{"id": roleObj[0].id}],
               "sites" : value,
               "first_name" : newArr[objIndex].first_name,
               "last_name" : newArr[objIndex].last_name
           }
       }
       
        axios
        .post('/api/ua/user/'+userid, postObj)
        .then((response) => {
            if(response.data.status === 1){
                getInitialViewData();
                props.forceUpdateUsers();
            }
        }).catch(function(error){
            props.userLogout();
        });
    };

    const deletUser = (user) => {
        const userID = user.id;
        const deleteUserURL = '/api/ua/user/'+userID+'/delete';
        axios.get(deleteUserURL).then((response) => {
            if(response.data.status === 1){
                props.setModalClass('success-added');
                props.upstreamToModal(response.data.message);
                    // props.setModalClass('request-process');
                    props.toggleModal();
                   
            } else{
                    props.setModalClass('sim-activation-error');
                    props.toggleModal()
            }
        }).catch(function(error){
            props.userLogout();
        });
    }

    const addUser = () => {
        props.setModalClass('clear-modal');
        setTimeout(function(){
            props.setModalClass('add-user-modal');
            props.toggleModal();
        },20)
        
    }

    const usersColumns: GridColDef[] = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth:120 },
        { field: 'email', headerName: 'Email', flex: 1, minWidth: 200 },
        { field: 'role', headerName: 'Role', flex: 1, minWidth: 270,
        renderCell: (params: GridCellParams) => {
            return(
                <Select 
                    className="role-select grid-select" 
                    size="small"
                    onChange={(event) => {
                        handleSelectChange(params.row.id, 'update-role', event.target.value);
                       
                    }}
                    value={params.row.role}
                >
                    {props.roles.map((role) => {
                        return(
                            <MenuItem key={role.id} value={role.name}>{role.display_name}</MenuItem>
                        );
                    })}
                </Select >
            )
        } },
        { field: 'sites', headerName: 'Site(s)', flex: 1, minWidth: 270,
        renderCell: (params: GridCellParams) => {
            let userSites = [];

            if(props.users.find(x => x.id === params.row.id)){
                userSites = props.users.find(x => x.id === params.row.id).sites;
            }
            return(            
            <Autocomplete
            multiple
            limitTags={2}
            disableClearable={true}
            className="grid-autocomplete"
            size="small"
            options={props.sites}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
                handleSelectChange(params.row.id, 'update-sites', value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={userSites}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                    key={`site-${option.id}`}
                    label={option.name}
                    />
                ))
                }
            renderInput={(params) => (
                <TextField {...params} label="" placeholder="" />
            )}
            />
            )
        } },
        { field: 'delete', type: 'delete', headerName: '', width: 60,
        renderCell: (params: GridCellParams) => {
            if (accountData.email === params.row.email){
                return null;
            } else{
                return(
                    <div className="delete-user" onClick={event => {
                        deletUser(params.row)
                    }}>
                        <DeleteIcon style={{fill: "#CF4520"}} />
                    </div>
                )
            }
            
        } },
    ];

    if(!activeUsers) return null;

    return(
        <div className="account-view">
             <h1 className="view-heading">My Account</h1>
            <p>View your account details and create/manage other users. Order new SIMs.</p>
            <div className="account-info-bar">
                <div className="section-card">
                    <div className="card-interior-row">
                            <div class="interior-wrap">
                            <h2 className="card-label">Your Information</h2>
                            <h2 className="accountholder">{`${accountData.first_name} ${accountData.last_name}`}</h2>
                            <div className="accountholder-details">
                                {accountData.email}<br/>
                                You are a &nbsp;
                                <strong>{actualRole}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="card-interior-row">
                        <div class="interior-wrap">
                            <span className="support-note">To make changes, please submit a support ticket <Link to="/contact">here</Link>.</span>
                        </div>
                    </div>
                </div>
                {/* end .section-card */}
                <div className="section-card">
                    <div className="card-interior-row">
                        <div class="interior-wrap">
                        <h2 className="card-label">Your SIMs</h2>
                        <div className="sims-count-row">
                            <div className="count-item">
                                <span className="count">{simsCount.active_sims}</span> Active
                            </div>
                            <div className="count-item">
                                <span className="count">{simsCount.available_sims}</span> Available
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="card-interior-row">
                        <div class="interior-wrap">
                        <h2 className="card-label">Order More SIMs</h2>
                        <OrderSims 
                            simsOrdered={props.simsOrdered}
                            toggleModal={props.toggleModal}
                            setModalClass={props.setModalClass}
                            upstreamToModal={props.upstreamToModal}
                            userLogout={props.userLogout}
                        />
                        </div>
                    </div>
                </div>              
            </div>
            {/* end .account-info-bar */}
            <div className="account-users-section">
                <h2 className="section-title">Manage Users</h2>
                <div className="users-listing-wrap">
                    <DataGrid
                        rows={activeUsers} 
                        columns={usersColumns}
                        disableColumnMenu={true}
                        forceUpdateUsers={ props.forceUpdateUsers}
                        initialState={{
                            pinnedColumns: {
                              right: ['delete']
                            }
                          }}
                    />
                </div>
                <div className="add-user-row">
                    <div className="add-user" onClick={e =>{
                        addUser();
                        
                    }}>
                        <PersonAddIcon />
                        <span>Add new user</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountView;