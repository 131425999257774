import React from 'react';
import AddUserForm from './AddUserForm';
import SIMActivationProgress from './SIMActivationProgress';
import TicketSubmitted from './TicketSubmitted';
import TermsModal from './TermsModal';
import EnableCookiesModal from './EnableCookiesModal';
import FailedLoginModal from './FailedLoginModal';
import APIMessageModal from './APIMessageModal';
import {ReactComponent as CloseX} from '../images/close-x.svg';

function Modal(props){

    return(
        <div className={`modal-wrapper ${props.showModal ? 'visible-modal' : ''} ${props.modalType}`}>
            <div className="modal-overlay" onClick={() =>{
                    props.triggerModal();
                    props.forceUpdate();
                }
                }></div>
            <div className="modal-window">
                {(
                    props.modalType === 'request-process' || 
                    props.modalType === 'failed-login' ||
                    props.modalType === 'add-user-modal' ||
                    props.modalType === 'api-message-modal' ||
                    props.modalType === 'image-modal'
                )&&
                    <button className="close-modal" onClick={() =>{
                        props.triggerModal();
                        props.forceUpdate();
                    }}>
                    <CloseX />
                    </button> 
                }
                {props.modalType === 'add-user-modal' &&
                    <AddUserForm 
                        roles={props.roles}
                        sites={props.sites}
                        triggerModal={props.triggerModal}
                        setModalClass={props.setModalClass}
                        upstreamToModal={props.upstreamToModal}
                    />
                }
                {
                    props.modalType === 'sim-activation-progress' &&
                    <SIMActivationProgress 
                        activationStatus={props.modalType}
                        forceUpdate={props.forceUpdate}
                    />
                }
                {
                    props.modalType === 'sim-activation-complete' &&
                    <SIMActivationProgress 
                        activationStatus={props.modalType}
                        triggerModal={props.triggerModal}
                        forceUpdate={props.forceUpdate}
                    />
                }
                {
                    props.modalType === 'sim-activation-error' &&
                    <SIMActivationProgress 
                        activationStatus={props.modalType}
                        triggerModal={props.triggerModal}
                        forceUpdate={props.forceUpdate}
                    />
                }
                {
                    props.modalType === 'sim-order-received' &&
                    <SIMActivationProgress 
                        activationStatus={props.modalType}
                        triggerModal={props.triggerModal}
                        forceUpdate={props.forceUpdate}
                    />
                }
                {
                    (props.modalType === 'ticket-submitted' || props.modalType === 'ticket-error') &&
                    <TicketSubmitted 
                        ticketStatus={props.modalType}
                        triggerModal={props.triggerModal}
                    />
                }
                {
                    props.modalType === 'terms-and-conditions' &&
                    <TermsModal 
                        triggerModal={props.triggerModal}
                        checkTerms={props.checkTerms}
                    />
                    
                }
                {
                    props.modalType === 'request-process' &&
                    <SIMActivationProgress 
                        activationStatus={props.modalType}
                        triggerModal={props.triggerModal}
                        alternateHeading="Your request is being processed."
                        alternateParagraph="Please check back momentarily."
                        forceUpdate={props.forceUpdate}
                    />
                }
                {
                    props.modalType === 'success-added' &&
                    <SIMActivationProgress 
                        activationStatus={props.modalType}
                        triggerModal={props.triggerModal}
                        alternateHeading={props.responseMessage}
                        forceUpdate={props.forceUpdate}
                       
                    />
                }
                {
                    props.modalType === 'enable-cookies' &&
                    <EnableCookiesModal
                        triggerModal={props.triggerModal}
                    />
                }
                {
                    props.modalType === 'failed-login' &&
                    <FailedLoginModal
                        triggerModal={props.triggerModal}
                    />
                }
                {
                    props.modalType === 'api-message-modal' &&
                    <APIMessageModal
                        responseMessage={props.responseMessage}
                    />
                }
                {
                    props.modalType === 'image-modal' &&
                   <div className="image-modal">
                        <img src={props.responseMessage} alt="" />
                   </div>
                }
                {props.modalType === 'clear-modal' &&
                    <div></div>
                }
                
            </div>
        </div>
    )
}

export default Modal;